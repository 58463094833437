// VARIABLES

$stratos: #010033;
$malibu: #44D0FE;
$clear: #F7FFFD;
$aquamarine: #7FFED1;


// BASIC

body{
    margin: 0;
    font-size: 18px;
    line-height:24px;
    font-family:'Poppins';

    @media(min-width:992px){
        font-size: 20px;
        line-height:24px;
        .privacity_label {
            font-size: 18px;
        }
        .privacity_text {
            font-size: 12px;
        }
        .privacity_checkbox {
            width: 30px;
            height: 30px;
        }
    }
}

ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

a{
    color: inherit;
    text-decoration: none;
}

p{
    margin: 0;
}

// COOKIES

.cookies{

    $self: &;

    background-color: white;
    font-size: 14px;
    line-height:19px;
    width: calc(100% - 80px);
    max-width: 400px;
    padding: 20px;
    bottom: 20px;
    position: fixed;
    right: 20px;
    box-shadow: #00000014 0 0 22px;
    z-index:30;

    @media(min-width:768px){
        width: calc(100% - 100px);
        padding: 30px;
        font-size: 16px;
        line-height:22px;
    }
}

.cookies_buttons {
    display: flex;
    justify-content: space-between;
}

.privacity_label {
    font-size: 18px;
}

.privacity_text {
    font-size: 10px;
    margin-bottom:20px;
}

.privacity_checkbox {
    width: 70px;
    height: 70px;
}

.form__check {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.show_cookies {
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 99;
}
// TEXT

.text{

    $self: &;

    &-size{

        &--xxxl{
            font-size: 56px;
            line-height: 56px;

            @media(min-width:992px){
                font-size: 96px;
                line-height: 96px;
            }
        }

        &--xl{
            font-size: 46px;
            line-height: 46px;

            @media(min-width:992px){
                font-size: 72px;
                line-height: 72px;
            }
        }

        &--l{
            font-size: 32px;
            line-height: 45px;

            @media(min-width:992px){
                font-size: 56px;
                line-height: 67px;
            }
        }

        &--ml{
            font-size: 28px;
            line-height: 33px;

            @media(min-width:992px){
                font-size: 40px;
                line-height: 48px;
            }
        }

        &--m{
            font-size: 23p;
            line-height: 34px;

            @media(min-width:992px){
                font-size: 30px;
                line-height: 42px;
            }
        }

        &--xs{
            font-size: 14px;
            line-height: 16px;
        }
    }

    &-family{

        &--cocogoose{
            font-family: 'Cocogoose Classic';
        }
    }

    &-style{

        &--bold{
            font-weight: 500;
        }

        &--underline{
            text-decoration: underline;
        }
    }

    &-gradient{

        &--blue{
            background: linear-gradient(to right, #7ffed1 0%, #44d0fe 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

// MODAL



.modal {
    display: none;
    position: fixed;
    z-index: 8887;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    
    background-color: #000000a1;
    
    transition: all 1s ease-in-out;
  
    // Modal Content/Box
    &-content {
      background: #FFF;
      margin: 10vh auto;
      overflow-y: auto;
      padding: 50px 10px 30px 10px;
      width: 90%;
      position: relative;
      border-radius:20px;
    }
  
    &-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  
    // Close button
    &-close {
        color: black;
        text-decoration: none;
        font-size: 38px;
        font-weight: 100;
        position: absolute;
        top: 20px;
        right: 20px;
      &:hover,
      &:focus {
        text-decoration: none;
        cursor: pointer;
      }
    }
  
    &-title {
      color: #FFF;
    }
  }
  
  @media (min-width: 1366px) {
    .modal-content {
      margin: 10vh auto;
      padding: 70px 50px;
      width: 30%;
    }
  }
  

// CAROUSEL


.slick-next::before, .slick-prev::before {
    color: blue;
}

.slick-prev{
    display: none !important;
}

.slick-next{
    left: -30px;
    bottom: 0;
    top: auto;
}

.slick-slide{
    padding: 0 15px 0  0;
}

.slick-list{padding:0 35% 0 0 !important;}

.slick-next:before {
    content: url(../img/arrow-right.svg) !important;
    position: absolute;
    top: 136px;
    right: 30px;
}

// FOOTER

.footer{

    $self: &;

    &__menu{

        display:flex;
        align-items:flex-start;
        flex-direction: column;
        margin-bottom:60px;
        color: white;

        @media(min-width:600px){
            flex-direction: row;
            margin-bottom:120px;
        }
        
        @media(min-width:1150px){
            align-items:center;
        }

        &-left{
            width:100%;
            text-align:center;

            @media(min-width:600px){
                width:150px;
                text-align:left;
            }

            @media(min-width:1450px){
                width:450px;
            }

            img{

                height: 50px;

                @media(min-width:1450px){
                    height: inherit;
                }
            }
        }

        &-center{

            width:100%;
            display:flex;
            justify-content:center;
            padding: 30px 0;

            @media(min-width:600px){
                width:calc(100% - 360px);
                padding: 0 0 0 40px;
                justify-content:flex-start;
            }

            @media(min-width:1150px){
                flex-direction: row;
                justify-content:flex-end;
                padding: 0;

            }

            @media(min-width:1450px){
                width:calc(100% - 650px);
            }

            ul{
                display:flex;
                flex-direction: column;
                font-family:'Cocogoose Classic';

                @media(min-width:1150px){
                    flex-direction: row;
                }

                li{
                    margin: 0 0 10px 0;
                    text-align:center;

                    @media(min-width:600px){
                        margin:0 20px 0 0;
                        text-align:left;
                    }

                    &:last-child{
                        margin-right: 0;
                    }

                    a{
                        &:hover{
                            opacity: 0.8;
                        }
                    }
                }
            }
        }

        &-right{
            width:100%;
            display:flex;
            justify-content:center;

            @media(min-width:600px){
                width:200px;
                justify-content:flex-end;
            }
        }
    }

    &__bottom{

        display:flex;
        flex-direction: column-reverse;
        align-items: center;
        font-size: 14px;
        line-height:20px;
        color: $aquamarine;

        @media(min-width:1150px){
            flex-direction: row;
            align-items: flex-end;

        }

        &-left{
            width:100%;
            text-align:center;

            @media(min-width:1150px){
                width:50%;
                text-align:left;

            }
        }

        &-right{
            display:flex;
            flex-direction: column-reverse;
            width:100%;
            justify-content:center;
            align-items: center;

            @media(min-width:768px){
                flex-direction: row;
            }

            @media(min-width:1150px){
                justify-content:flex-end;
                align-items: flex-end;
                width:50%;


            }

            ul{
                display: flex;
                align-items: flex-end;

                li{
                    margin: 15px 5px 15px 5px;

                    @media(min-width:768px){
                        margin: 0 40px 0 0;
                    }

                    a{
                        &:hover{
                            opacity: 0.8;
                        }
                    }
                }
            }

            img{
                height: 31px;
            }
        }
    }
}

// IMG

.img{

    $self: &;

    &-detail{

        &.whois{
            top: 0;

            @media(min-width:992px){
                top: 170px;
            }
        }
    }
}

// GRID

.grid{

    $self: &;
    display: flex;
    flex-direction: column;

    @media(min-width:992px){
        flex-direction: row;
    }

    &__mixed{
        
        display: flex;
        flex-direction: column;

        @media(min-width:992px){
           flex-direction: row;
        }
        
        &-left{
            width:100%;
            padding:0 0 0 0;

            @media(min-width:992px){
                width:35%;
                padding:0 60px 0 0;
            }
        }

        &-right{
            width:100%;

            @media(min-width:992px){
                width:65%;
            }
        }
    }

    &__col{

        width: 100%;
        padding: 0 0 30px 0;

        @media(min-width:992px){
            padding: 0 15px;
        }

        &:first-child{
            padding: 0 0 30px 0;

            @media(min-width:992px){
                padding: 0 30px 0 0;
            }
        }

        &:last-child{
            padding: 0 0 0 0;

            @media(min-width:992px){
                padding: 0 0 0 30px;
            }
        }

        &--25{
            width: 100%;

            @media(min-width:992px){
                width: 25%;
            }
        }

        &--75{
            width: 100%;

            @media(min-width:992px){
                width: 75%;
            }
        }
    }

    &__expand{

        display:flex;
        flex-direction: column;

        @media(min-width:992px){
            flex-direction: row;
        }

        &-left{

            width:calc(100% - 48px);

            @media(min-width:992px){
                width:calc(50% - 48px);
            }

            &-container{
                width:100%;
                max-width:100%;
                float:inherit;
                padding:0 0 0 0;

                @media(min-width:992px){
                    float:right;
                    width:calc(100% - 60px);
                    max-width:calc(1422px/2 - 60px);
                    padding:0 60px 0 0;
                }
            }
        }

        &-right{
            width:calc(100% - 48px);
            padding: 50px 24px 0 24px;

            @media(min-width:992px){
                width:50%;
                padding: 0;
            }
        }
    }
}

// COLOR

.color{

    $self: &;

    &-element{

        &--malibu{
            color: $malibu;
        }

        &--white{
            color: white;
        }

        &--aquamarine{
            color: $aquamarine;
        }
    }
    

    &-bg{

        &--clear{
            background-color: $clear;
        }

        &--aquamarine{
            background-color: $aquamarine;
        }

        &--stratos{
            background-color: $stratos;
        }
    }
}

// BTN

.btn{

    $self: &;

    background: rgb(127,254,209);
    background: linear-gradient(143deg, rgba(127,254,209,1) 21%, rgba(68,208,254,1) 78%);
    height: 53px;
    display: flex;
    border-radius: 80px;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    line-height: 38px;
    width: max-content;
    min-width: 250px;
    cursor: pointer;
    font-family: 'Cocogoose Classic';

    @media(min-width:992px){
        min-width: 320px;
        height: 80px;
        font-size: 42px;
        line-height: 47px;
    }

    &:hover{
        opacity: 0.8;
    }

    & > span{
        background-color: $stratos;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        border-radius: 80px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > strong{
            background: linear-gradient(to right, #7ffed1 0%, #44d0fe 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: normal;
            height:100%;
            display:flex;
            align-items:center;
            justify-content:center;
        }
    }

    &-size{

        &--l{
            font-size: 25px;
            height: 49px;

            @media(min-width:992px){
                font-size: 37px;
                height: 69px;
            }
        }

        &--s{
            height: 35px;
            min-width: 150px;
            font-size: 17px;
            line-height: 19px;

            @media(min-width:992px){
                height: 38px;
                min-width: 180px;
                font-size: 20px;
                line-height: 22px;
            }

            span{
                width: calc(100% - 4px);
                height: calc(100% - 4px);
            }
        }
    }

    &-line{

        border: 3px solid white;
        background: none;
        color: white;

        &--black{
            border: 3px solid black;
            color: black;

            &:hover{
                opacity: 0.6;
            }
        }
    }

    &-position{

        &--right-center-mobile{

            margin: 0 auto 0 auto;

            @media(min-width:992px){
                margin: 0 0 0 auto;
            }
        }

        &--left-center-mobile{

            margin: 0 auto 0 auto;

            @media(min-width:992px){
                margin: 0;
            }
        }
    }

    
}

// IMAGE TEXT

.image-text{

    $self: &;

    &__blocks{

        display: flex;
        flex-direction: column;

        @media(min-width:768px){
            flex-direction: row;
        }

        &-left{
 
            width:100%;
            padding:0 0 20px 0;

            @media(min-width:768px){
                width:43%;
                padding:0 30px 0 0;
            }

            &-container{
                background: rgb(127,254,209);
                background: linear-gradient(200deg, rgba(127,254,209,1) 21%, rgba(68,208,254,1) 78%);
                border-radius:20px;
                padding: 20px 20px 50px 20px;
                color: white;

                @media(min-width:992px){
                    padding: 40px 40px 120px 40px;
                }
            }

            &-pretitle{
                font-size:40px;
                line-height:55px;
            }

            &-title{
                font-size: 40px;
                line-height:43px;
                font-family:'Cocogoose Classic';

                @media(min-width:992px){
                    font-size: 60px;
                    line-height:83px;
                }
            }

            &-description{
                font-size: 21px;
                line-height:26px;
                color:black;

                @media(min-width:992px){
                    font-size: 24px;
                    line-height:28px;
                }
            }
        }

        &-right{

            width:100%;
            padding: 0 0 0 0;

            @media(min-width:768px){
                width:57%;
                padding: 0 0 0 30px;
            }

            img{
                border-radius:20px;
                width:auto;
                height:auto;
                max-width:100%;
                max-height:100%;
            }
        }

        &--reverse{

            flex-direction: column;

            @media(min-width:768px){
                flex-direction: row-reverse;
            }

            .image-text__blocks-left {
                padding: 0 0 20px 0;

                @media(min-width:768px){
                    padding: 0 70px 0 30px;
                }
            }

            .image-text__blocks-right {
                padding: 0 0 0 0;

                @media(min-width:768px){
                    padding: 0 30px 0 0;
                }
            }
        }
    }

    &__basic{

        display: flex;
        flex-direction: column;

        @media(min-width:992px){
            flex-direction: row;
        }

        &-title{
            color: $malibu;
            font-family: 'Cocogoose Classic';
        }

        &-left{
            width:100%;
            padding: 0 0 40px 0;

            @media(min-width:992px){
                width:calc(55% - 150px);
                padding: 0 150px 0 0;
            }
        }

        &-right{

            width:100%;
            position: relative;
            text-align: center;

            @media(min-width:992px){
                width:45%;
                text-align: inherit;

            }

            img{
                width:auto;
                max-width:250px;
                position: relative;
                top: 0;
                bottom: 0;
                margin: auto;

                @media(min-width:992px){
                    position: absolute;
                    max-width:100%;

                }
            }
        }
    }
}

// FORM

.form{

    $self:&;

    &__text{

        input{
            width: 100%;
            background-color: transparent;
            border: 0;
            border-bottom: 3px solid white;
            font-size: 20px;
            line-height:24px;
            padding: 10px 0;

            @media(min-width:992px){
                font-size: 24px;
                line-height:28px;
            }

            &::placeholder{
                color: white;
            }
        }
    }

    &__grid{

        display: flex;

        &-left{
            width:100%;
            max-width:700px;
            padding-top: 60px;
        }

        &-box{
            background: rgb(127,254,209);
            background: linear-gradient(237deg, rgba(127,254,209,1) 6%, rgba(68,208,254,1) 84%);          
            padding: 30px 20px;
            border-radius:20px;
            margin-top: 0;

            @media(min-width:992px){
                margin-top: -220px;
                padding: 40px;
            }

            &-title{
                font-family: 'Cocogoose Classic';
                padding-right: 0;

                @media(min-width:768px){
                    padding-right: 70px;
                }
            }

            &-description{
                padding-right: 0;
                font-size: 20px;
                line-height:24px;

                @media(min-width:768px){
                    padding-right: 70px;
                    font-size: 24px;
                    line-height:28px;
                }
            }
        }
    }

    &__message{

        padding:10px;
        border-radius:10px;

        @media(min-width:768px){
            padding:20px;
        }

        &--error{
            background-color: #fff4f4e0;
            color: #ff1414;
        }
    }

    &__error{

        &.form__text input {
            border-bottom: 3px solid #f61615;

            &::placeholder{
                color:#f61615;
            }
        }
        &.form__check label {
            color:#f61615;
        }
    }
}

// Custom focus

input:focus, select:focus, select, input.form-control:focus {
    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}



// HEADER

.header{

    $self: &;

    background-color: $stratos;  
    color: white;
    min-height:auto;
    padding-top: 10px;

    @media(min-width:1250px){
        min-height:1100px;
        padding-top: 40px;
    }

    &--nobanner {
        min-height: auto;
    }

    &__content{

        position: relative;
        
        &-head{

            height: 71px;

            @media(min-width:768px){
                height: 91px;
            }

            &-cols{
                
               

                &.fixed{
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    background-color: #050033;
                    z-index: 21;
                    

                    .header__content-head-cols-container{
                        width:calc(100% - 30px);
                        max-width: 1494px;
                        padding: 0 15px;
                        height:71px;
                        transition: all 500ms;

                        .header__content-head-left img{
                            transition: all 500ms;
                            height: 45px;
                            top: 2px;

                            @media(min-width:768px){
                                height: 60px;
                            }

                           

                        }
                    }
                }

                &-container{
                    display: flex;
                    align-items:center;
                    position: relative;
                    z-index: 1;
                    height: 71px;
                    transition: all 500ms;
                    margin: auto;

                    @media(min-width:768px){
                        height: 91px;
                    }
                }
                
            }

            &-left{
                width: 140px;

                @media(min-width:1250px){
                    width: 250px;
                }

                img{
                    position: relative;
                    top: 0;
                    height: 45px;
                    transition: all 500ms;

                    @media(min-width:768px){
                        height: 60px;
                    }

                    @media(min-width:1150px){
                        top: -10px;
                    }

                    @media(min-width:1250px){
                        height: 86px;
                    }
                }
            }
        
            &-center{
    
                display: none;

                @media(min-width:1150px){
                    display:flex;
                    width: calc(100% - 370px);
                    display:flex;
                    justify-content:flex-end;
                    padding: 0 10px 0 0;
                }

                @media(min-width:1250px){
                    width: calc(100% - 620px);
                }

                ul{
                    display: flex;
                    font-size: 18px;
                    line-height: 20px;
                    font-family: 'Cocogoose Classic';

                    @media(min-width:1250px){
                        font-size: 20px;
                    }

                    li{
                        margin: 0 15px 0 0;

                        @media(min-width:1400px){
                            margin: 0 20px 0 0;
                        }

                        &:last-child{
                            margin: 0;
                        }

                        a{
                            &:hover{
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }

            &-right{
                width: calc(100% - 190px);
                display: flex;
                justify-content:center;

                @media(min-width:1150px){
                    width: 200px;
                    justify-content:flex-end;
                }
                
            }

            &-lang{

                width:170px;
                font-size: 16px;
                text-align: right;
                display: none;

                @media(min-width:1150px){
                    align-items:center;
                    display: block;
                }

                a{
                    float:right;
                    padding: 0 0 0 20px;
                    display:flex;
                    align-items:center;
                    justify-content: flex-end;
                    width: max-content;
                    border: 0;
                    background-color: transparent;
                    color: inherit;
                    font-size: inherit;
                    position: relative;
                    cursor: pointer;

                    &:before{

                        content: "";
                        position:absolute;
                        border-left: 1px solid #70708b;
                        height:30px;
                        left:0;
                    }
                }

                i{
                    opacity: 0.8;
                    font-style:normal;
                }

                img{
                    height:20px;
                    margin: 0 10px 0 0;
                }

                &-mobile{

                    border-top: 1px solid #ffffff4f;
                    margin-top: 40px;
                    padding-top: 40px;
                    color: #9292a7;
                    font-size: 15px;
                    display: flex;

                    img{
                        height:20px;
                        margin: 0 10px 0 0;
                    }

                    a{
                        border: 0;
                        background-color: transparent;
                        font-size: inherit;
                        color: inherit;
                        display: flex;
                        align-items: center;
                    }
                }
            }

            &-menu-mobile{

                display: block;
                width: 50px;
                display:flex;
                justify-content:flex-end;

                @media(min-width:1150px){
                    display:none;
                }

                
            }
        }

        

        
    }

    &__info{
        
        margin-top: 40px;
        position: relative;
        z-index: 1;

        @media(min-height:992px){
            margin-top: 70px;
        }

        &-left{
            width: 100%;

            @media(min-height:992px){
                width: 60%;
            }

            &-title{
                font-family:'Cocogoose Classic';
                color: $malibu;
                text-align:center;

                @media(min-width:992px){
                    text-align:left;
                }
            }

            .app{

                display: flex;
                flex-direction: row;
                justify-content: center;

                @media(min-width:992px){
                    justify-content: flex-start;
                }

                img{
                    margin: 0 10px 0 0;
                    height: 50px;

                   

                    @media(min-width:768px){
                        height: inherit;
                    }
                }
            }
        }

        &-right{

           

            
        }

        &-content{
            display: flex;
            flex-direction: column;
            align-items:center;
            justify-content:center;
            min-height: auto;
            padding-bottom: 50px;

            @media(min-width:992px){
                flex-direction: row;
                min-height: 600px;
                padding-bottom: 0;
            }

            @media(min-width:1250px){
                min-height: 800px;
            }
        }

        &-img-mobile{

            display: block;
            text-align:center;

            @media(min-width:992px){
                display: none;
            }

            img{
                margin-top:50px;
                width:100%;
                max-width:400px;
            }
        }
        
    }

    &__img{

        position: absolute;
        top: 0;
        width: 100%;
        display: none;
        align-items: flex-end;
        justify-content: flex-end;

        @media(min-width:992px){
            display: flex;
        }

        img{
            max-width:50vw;
            position: relative;
            bottom: -300px;

            @media(min-width:1250px){
                max-width:70vw;
                bottom: -290px;

            }

            @media(min-width:1550px){
                max-width:1030px;
                bottom: -220px;
            }
        }
    }

    &__mobile-menu{

        position: fixed;
        top: 0;
        width: calc(100% - 60px);
        background-color: $stratos;
        height: 100%;
        z-index: 22;
        padding: 30px;
        right: -100%;
        transition: all 500ms;

        &.open{
            right:0%;
            transition: all 500ms;
        }

        ul{
                    
            li{
                margin: 0 0 20px 0;
            }
        }

        &-head{
            display:flex;
            justify-content:space-between;
            align-items:center;
        }

        &-content{
            padding: 60px 0 0 0;
        }
    }
}


// ACCORDION


.accordion {
    
    $self: &;

    &-header{
        padding: 20px 40px 20px 0;
        cursor: pointer;
        transition: all .3s;
        color: $malibu;
        font-size: 24px;
        line-height:30px;
        border-bottom: 3px solid $malibu;
        position: relative;
        margin-bottom: 10px;
        
        @media(min-width:992px){
            font-size: 36px;
            line-height:43px;
        }

        &:hover{
            position: relative;
            z-index: 5;
        }
    }

    &-body{
        display: none;

        &__contents{
            padding: 20px 0;

            p{
                margin: 0 0 15px 0;
            }

            ul{
                list-style: circle;
                padding-left: 30px;
                
                li{
                    margin: 0 0 15px 0;
                }
            }
        }
    }

    &__item{

        & > .accordion-header:after {
            content: url(../img/more-small.svg);
            position: absolute;
            transition: .3s all;
            transform: rotate(0deg);
            right: 0;

            @media(min-width:992px){
                content: url(../img/more.svg);
            }
        }

        &.active{

            > .accordion-header:after {
                content: url(../img/less-small.svg);

                @media(min-width:992px){
                    content: url(../img/less.svg);
                }
            }
        }
    }
}
  
 
// anchor

.anchor{

    $self: &;

    position: relative;
    top: -100px;
}
 

// CONTAINER

.container{

    $self: &;

    width: 100%;
    margin-left: auto;
    margin-right: auto;
    
    &--xxl{
        
        max-width: 1542px;
    }
}

// UTILITIES


.u-noscroll{
    overflow: hidden;
}

.u-width-100{
    width: 100%;
}

.u-radius-20{
    border-radius: 20px;
}

.u-pos-relative{
    position: relative;
}

.u-image-correction{
    margin-bottom:-8px;
}

.u-text-decoration-none{
    text-decoration: none;
}

.u-visible-xs{
    
    display: block !important;

    @media(min-width:992px){
        display: none !important;
    }
}

.u-hidden-xs{
    
    display: none !important;

    @media(min-width:992px){
        display: block !important;
    }
}


@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Regular.eot');
    src: url('../fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Poppins-Regular.woff2') format('woff2'),
        url('../fonts/Poppins-Regular.woff') format('woff'),
        url('../fonts/Poppins-Regular.ttf') format('truetype'),
        url('../fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cocogoose Classic';
    src: url('../fonts/CocogooseClassic-Medium.eot');
    src: url('../fonts/CocogooseClassic-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CocogooseClassic-Medium.woff2') format('woff2'),
        url('../fonts/CocogooseClassic-Medium.woff') format('woff'),
        url('../fonts/CocogooseClassic-Medium.ttf') format('truetype'),
        url('../fonts/CocogooseClassic-Medium.svg#CocogooseClassic-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cocogoose Classic';
    src: url('../fonts/CocogooseClassic-Regular.eot');
    src: url('../fonts/CocogooseClassic-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/CocogooseClassic-Regular.woff2') format('woff2'),
        url('../fonts/CocogooseClassic-Regular.woff') format('woff'),
        url('../fonts/CocogooseClassic-Regular.ttf') format('truetype'),
        url('../fonts/CocogooseClassic-Regular.svg#CocogooseClassic-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



////////////// SPACE SYSTEM ///////////////

/////// MARGIN /////// 

/////// SPACE GENERAL /////// 

.u-px-contain{padding: 0 15px !important;}
.u-px-contain-xl{padding: 0 28px !important;}
.u-mb-module{margin-bottom: 50px !important;}

@media(min-width:768px) {
    .u-px-contain{padding: 0 24px !important;}
    .u-px-contain-xl{padding: 0 0 !important;}
}

@media(min-width:992px) {
    .u-mb-module{margin-bottom: 420px !important;}
}



// MARGIN RESET

.u-m-0{margin: 0 !important;}
.u-mt-0{margin-top:0 !important}
.u-ml-0{margin-left: 0 !important;} 
.u-mr-0{margin-right:0 !important;}
.u-mb-0{margin-bottom: 0 !important;}


// MARGIN (TOP / BOTTOM / LEFT / RIGHT)

.u-m-xxxxs{margin: 2px !important;}
.u-m-xxxs{margin: 4px !important;}
.u-m-xxs{margin: 8px !important;}
.u-m-xs{margin: 12px !important;}
.u-m-s{margin: 16px !important;}
.u-m-m{margin: 20px !important;}
.u-m-l{margin: 24px !important;}
.u-m-xl{margin: calc(32px / 2) !important;}
.u-m-xxl{margin: calc(48px / 2) !important;}
.u-m-xxxl{margin: calc(64px / 2) !important;}
.u-m-xxxxl{margin: calc(96px / 2) !important;}


@media(min-width:768px) {
    .u-m-xxxxs{margin: 2px !important;}
    .u-m-xxxs{margin: 4px !important;}
    .u-m-xxs{margin: 8px !important;}
    .u-m-xs{margin: 12px !important;}
    .u-m-s{margin: 16px !important;}
    .u-m-m{margin: 20px !important;}
    .u-m-l{margin: 24px !important;}
    .u-m-xl{margin: calc(32px / 1.5) !important;}
    .u-m-xxl{margin: calc(48px / 1.5)  !important;}
    .u-m-xxxl{margin: calc(64px / 1.5)  !important;}
    .u-m-xxxxl{margin: calc(96px / 1.5)  !important;}
}

@media(min-width:992px) {
    .u-m-xxxxs{margin: 2px !important;}
    .u-m-xxxs{margin: 4px !important;}
    .u-m-xxs{margin: 8px !important;}
    .u-m-xs{margin: 12px !important;}
    .u-m-s{margin: 16px !important;}
    .u-m-m{margin: 20px !important;}
    .u-m-l{margin: 24px !important;}
    .u-m-xl{margin: 32px !important;}
    .u-m-xxl{margin: 48px !important;}
    .u-m-xxxl{margin: 64px !important;}
    .u-m-xxxxl{margin: 96px !important;}
}


// MARGIN TOP

.u-mt-xxxxs{margin-top: 2px !important;}
.u-mt-xxxs{margin-top: 4px !important;}
.u-mt-xxs{margin-top: 8px !important;}
.u-mt-xs{margin-top: 12px !important;}
.u-mt-s{margin-top: 16px !important;}
.u-mt-m{margin-top: 20px !important;}
.u-mt-l{margin-top: calc(24px / 2) !important;}
.u-mt-xl{margin-top: calc(32px / 2) !important;}
.u-mt-xxl{margin-top: calc(48px / 2) !important;}
.u-mt-xxxl{margin-top: calc(64px / 2) !important;}
.u-mt-xxxxl{margin-top: calc(96px / 2) !important;}


@media(min-width:768px) {
    .u-mt-xxxxs{margin-top: 2px !important;}
    .u-mt-xxxs{margin-top: 4px !important;}
    .u-mt-xxs{margin-top: 8px !important;}
    .u-mt-xs{margin-top: 12px !important;}
    .u-mt-s{margin-top: 16px !important;}
    .u-mt-m{margin-top: 20px !important;}
    .u-mt-l{margin-top: calc(24px / 1.5) !important;}
    .u-mt-xl{margin-top: calc(32px / 1.5) !important;}
    .u-mt-xxl{margin-top: calc(48px / 1.5) !important;}
    .u-mt-xxxl{margin-top: calc(64px / 1.5) !important;}
    .u-mt-xxxxl{margin-top: calc(96px / 1.5) !important;}
}

@media(min-width:992px) {
    .u-mt-xxxxs{margin-top: 2px !important;}
    .u-mt-xxxs{margin-top: 4px !important;}
    .u-mt-xxs{margin-top: 8px !important;}
    .u-mt-xs{margin-top: 12px !important;}
    .u-mt-m{margin-top: 20px !important;}
    .u-mt-l{margin-top: 24px !important;}
    .u-mt-xl{margin-top: 32px !important;}
    .u-mt-xxl{margin-top: 48px !important;}
    .u-mt-xxxl{margin-top: 64px !important;}
    .u-mt-xxxxl{margin-top: 96px !important;}
}


// MARGIN BOTTOM

.u-mb-xxxxs{margin-bottom: 2px !important;}
.u-mb-xxxs{margin-bottom: 4px !important;}
.u-mb-xxs{margin-bottom: 8px !important;}
.u-mb-xs{margin-bottom: 12px !important;}
.u-mb-s{margin-bottom: 16px !important;}
.u-mb-m{margin-bottom: 20px !important;}
.u-mb-l{margin-bottom: 24px !important;}
.u-mb-xl{margin-bottom: calc(32px / 2) !important;}
.u-mb-xxl{margin-bottom: calc(48px / 2)  !important;}
.u-mb-xxxl{margin-bottom: calc(64px / 2)  !important;}
.u-mb-xxxxl{margin-bottom: calc(96px / 2)  !important;}


@media(min-width:768px) {
    .u-mb-xxxxs{margin-bottom: 2px !important;}
    .u-mb-xxxs{margin-bottom: 4px !important;}
    .u-mb-xxs{margin-bottom: 8px !important;}
    .u-mb-xs{margin-bottom: 12px !important;}
    .u-mb-s{margin-bottom: 16px !important;}
    .u-mb-m{margin-bottom: 20px !important;}
    .u-mb-l{margin-bottom: 24px !important;}
    .u-mb-xl{margin-bottom: calc(32px / 1.5) !important;}
    .u-mb-xxl{margin-bottom: calc(48px / 1.5)  !important;}
    .u-mb-xxxl{margin-bottom: calc(64px / 1.5)  !important;}
    .u-mb-xxxxl{margin-bottom: calc(96px / 1.5)  !important;}
}

@media(min-width:992px) {
    .u-mb-xxxxs{margin-bottom: 2px !important;}
    .u-mb-xxxs{margin-bottom: 4px !important;}
    .u-mb-xxs{margin-bottom: 8px !important;}
    .u-mb-xs{margin-bottom: 12px !important;}
    .u-mb-s{margin-bottom: 16px !important;}
    .u-mb-m{margin-bottom: 20px !important;}
    .u-mb-l{margin-bottom: 24px !important;}
    .u-mb-xl{margin-bottom: 32px !important;}
    .u-mb-xxl{margin-bottom: 48px !important;}
    .u-mb-xxxl{margin-bottom: 64px !important;}
    .u-mb-xxxxl{margin-bottom: 96px !important;}
}


// MARGIN LEFT

.u-ml-xxxxs{margin-left: 2px !important;}
.u-ml-xxxs{margin-left: 4px !important;}
.u-ml-xxs{margin-left: 8px !important;}
.u-ml-xs{margin-left: 12px !important;}
.u-ml-s{margin-left: 16px !important;}
.u-ml-m{margin-left: 20px !important;}
.u-ml-l{margin-left: 24px !important;}
.u-ml-xl{margin-left: 32px !important;}
.u-ml-xxl{margin-left: 48px !important;}
.u-ml-xxxl{margin-left: 64px !important;}
.u-ml-xxxxl{margin-left: 96px !important;}

// MARGIN RIGHT

.u-mr-xxxxs{margin-right: 2px !important;}
.u-mr-xxxs{margin-right: 4px !important;}
.u-mr-xxs{margin-right: 8px !important;}
.u-mr-xs{margin-right: 12px !important;}
.u-mr-s{margin-right: 16px !important;}
.u-mr-m{margin-right: 20px !important;}
.u-mr-l{margin-right: 24px !important;}
.u-mr-xl{margin-right: 32px !important;}
.u-mr-xxl{margin-right: 48px !important;}
.u-mr-xxxl{margin-right: 64px !important;}
.u-mr-xxxxl{margin-right: 96px !important;}


// MARGIN (LEFT / RIGHT)

.u-mx-xxxxs{margin: 0 2px 0 2px !important;}
.u-mx-xxxs{margin: 0 4px 0 4px !important;}
.u-mx-xxs{margin: 0 8px 0 8px !important;}
.u-mx-xs{margin: 0 12px 0 12px !important;}
.u-mx-s{margin: 0 16px 0 16px !important;}
.u-mx-m{margin: 0 20px 0 20px !important;}
.u-mx-l{margin: 0 24px 0 24px !important;}
.u-mx-xl{margin: 0 32px 0 32px !important;}
.u-mx-xxl{margin: 0 48px 0 48px !important;}
.u-mx-xxxl{margin: 0 64px 0 64px !important;}
.u-mx-xxxxl{margin: 0 96px 0 96px !important;}


// MARGIN (TOP / BOTTOM)

.u-my-xxxxs{margin: 2px auto 2px auto !important;}
.u-my-xxxs{margin: 4px auto 4px auto !important;}
.u-my-xxs{margin: 8px auto 8px auto !important;}
.u-my-xs{margin: 12px auto 12px auto !important;}
.u-my-s{margin: 16px auto 16px auto !important;}
.u-my-m{margin: 20px auto 20px auto !important;}
.u-my-l{margin: 24px auto 24px auto !important;}
.u-my-xl{margin: calc(32px / 2) auto calc(32px / 2) auto !important;}
.u-my-xxl{margin: calc(48px / 2) auto calc(48px / 2)  auto !important;}
.u-my-xxxl{margin: calc(64px / 2) auto calc(64px / 2)  auto !important;}
.u-my-xxxxl{margin: calc(96px / 2) auto calc(96px / 2)  auto !important;}


@media(min-width:768px) {
    .u-my-xxxxs{margin: 2px auto 2px auto !important;}
    .u-my-xxxs{margin: 4px auto 4px auto !important;}
    .u-my-xxs{margin: 8px auto 8px auto !important;}
    .u-my-xs{margin: 12px auto 12px auto !important;}
    .u-my-s{margin: 16px auto 16px auto !important;}
    .u-my-m{margin: 20px auto 20px auto !important;}
    .u-my-l{margin: 24px auto 24px auto !important;}
    .u-my-xl{margin: calc(32px / 1.5) auto calc(32px / 1.5) auto !important;}
    .u-my-xxl{margin: calc(48px / 1.5) auto calc(48px / 1.5)  auto !important;}
    .u-my-xxxl{margin: calc(64px / 1.5) auto calc(64px / 1.5)  auto !important;}
    .u-my-xxxxl{margin: calc(96px / 1.5) auto calc(96px / 1.5)  auto !important;}
}

@media(min-width:992px) {
    .u-my-xxxxs{margin: 2px auto 2px auto !important;}
    .u-my-xxxs{margin: 4px auto 4px auto !important;}
    .u-my-xxs{margin: 8px auto 8px auto !important;}
    .u-my-xs{margin: 12px auto 12px auto !important;}
    .u-my-s{margin: 16px auto 16px auto !important;}
    .u-my-m{margin: 20px auto 20px auto !important;}
    .u-my-l{margin: 24px auto 24px auto !important;}
    .u-my-xl{margin: 32px auto 32px auto !important;}
    .u-my-xxl{margin: 48px auto 48px auto !important;}
    .u-my-xxxl{margin: 64px auto 64px auto !important;}
    .u-my-xxxxl{margin: 96px auto 96px auto !important;}
}


// MARGIN MOBILE (TOP)

.u-mt-xxxxs-responsive{margin-top: 2px !important;}
.u-mt-xxxs-responsive{margin-top: 4px !important;}
.u-mt-xxs-responsive{margin-top: 8px !important;}
.u-mt-xs-responsive{margin-top: 12px !important;}
.u-mt-s-responsive{margin-top: 16px !important;}
.u-mt-m-responsive{margin-top: 20px !important;}
.u-mt-l-responsive{margin-top: 24px !important;}
.u-mt-xl-responsive{margin-top: calc(32px / 2)  !important;}
.u-mt-xxl-responsive{margin-top: calc(48px / 2)  !important;}
.u-mt-xxxl-responsive{margin-top: calc(64px / 2)  !important;}
.u-mt-xxxxl-responsive{margin-top: calc(96px / 2)  !important;}


@media(min-width:992px) {

    .u-mt-xxxxs-responsive{margin-top: 0 !important;}
    .u-mt-xxxs-responsive{margin-top: 0 !important;}
    .u-mt-xxs-responsive{margin-top: 0 !important;}
    .u-mt-xs-responsive{margin-top: 0 !important;}
    .u-mt-s-responsive{margin-top: 0 !important;}
    .u-mt-m-responsive{margin-top: 0 !important;}
    .u-mt-l-responsive{margin-top: 0 !important;}
    .u-mt-xl-responsive{margin-top: 0 !important;}
    .u-mt-xxl-responsive{margin-top: 0 !important;}
    .u-mt-xxxl-responsive{margin-top: 0 !important;}
    .u-mt-xxxxl-responsive{margin-top: 0 !important;}

}


// MARGIN MOBILE (BOTTOM)

.u-mb-xxxxs-responsive{margin-bottom: 2px !important;}
.u-mb-xxxs-responsive{margin-bottom: 4px !important;}
.u-mb-xxs-responsive{margin-bottom: 8px !important;}
.u-mb-xs-responsive{margin-bottom: 12px !important;}
.u-mb-s-responsive{margin-bottom: 16px !important;}
.u-mb-m-responsive{margin-bottom: 20px !important;}
.u-mb-l-responsive{margin-bottom: 24px !important;}
.u-mb-xl-responsive{margin-bottom: calc(32px / 2)  !important;}
.u-mb-xxl-responsive{margin-bottom: calc(48px / 2)  !important;}
.u-mb-xxxl-responsive{margin-bottom: calc(64px / 2)  !important;}
.u-mb-xxxxl-responsive{margin-bottom: calc(96px / 2)  !important;}


@media(min-width:992px) {

    .u-mb-xxxxs-responsive{margin-bottom: 0 !important;}
    .u-mb-xxxs-responsive{margin-bottom: 0 !important;}
    .u-mb-xxs-responsive{margin-bottom: 0 !important;}
    .u-mb-xs-responsive{margin-bottom: 0 !important;}
    .u-mb-s-responsive{margin-bottom: 0 !important;}
    .u-mb-m-responsive{margin-bottom: 0 !important;}
    .u-mb-l-responsive{margin-bottom: 0 !important;}
    .u-mb-xl-responsive{margin-bottom: 0 !important;}
    .u-mb-xxl-responsive{margin-bottom: 0 !important;}
    .u-mb-xxxl-responsive{margin-bottom: 0 !important;}
    .u-mb-xxxxl-responsive{margin-bottom: 0 !important;}

}


// MARGIN TOP DESKTOP

.u-mt-xxxxs-desktop{margin-top: 0 !important;}
.u-mt-xxxs-desktop{margin-top: 0 !important;}
.u-mt-xxs-desktop{margin-top: 0 !important;}
.u-mt-xs-desktop{margin-top: 0 !important;}
.u-mt-s-desktop{margin-top: 0 !important;}
.u-mt-m-desktop{margin-top: 0 !important;}
.u-mt-l-desktop{margin-top: 0 !important;}
.u-mt-xl-desktop{margin-top: 0 !important;}
.u-mt-xxl-desktop{margin-top: 0 !important;}
.u-mt-xxxl-desktop{margin-top: 0 !important;}
.u-mt-xxxxl-desktop{margin-top: 0 !important;}

    @media(min-width:992px) {

        .u-mt-xxxxs-desktop{margin-top: 2px !important;}
        .u-mt-xxxs-desktop{margin-top: 4px !important;}
        .u-mt-xxs-desktop{margin-top: 8px !important;}
        .u-mt-xs-desktop{margin-top: 12px !important;}
        .u-mt-s-desktop{margin-top: 16px !important;}
        .u-mt-m-desktop{margin-top: 20px !important;}
        .u-mt-l-desktop{margin-top: 24px !important;}
        .u-mt-xl-desktop{margin-top: 32px !important;}
        .u-mt-xxl-desktop{margin-top: 48px !important;}
        .u-mt-xxxl-desktop{margin-top: 64px !important;}
        .u-mt-xxxxl-desktop{margin-top: 96px !important;}
    }

// MARGIN BOTTOM DESKTOP

.u-mb-xxxxs-desktop{margin-bottom: 0 !important;}
.u-mb-xxxs-desktop{margin-bottom: 0 !important;}
.u-mb-xxs-desktop{margin-bottom: 0 !important;}
.u-mb-xs-desktop{margin-bottom: 0 !important;}
.u-mb-s-desktop{margin-bottom: 0 !important;}
.u-mb-m-desktop{margin-bottom: 0 !important;}
.u-mb-l-desktop{margin-bottom: 0 !important;}
.u-mb-xl-desktop{margin-bottom: 0 !important;}
.u-mb-xxl-desktop{margin-bottom: 0 !important;}
.u-mb-xxxl-desktop{margin-bottom: 0 !important;}
.u-mb-xxxxl-desktop{margin-bottom: 0 !important;}


    @media(min-width:992px) {

        .u-mb-xxxxs-desktop{margin-bottom: 2px !important;}
        .u-mb-xxxs-desktop{margin-bottom: 4px !important;}
        .u-mb-xxs-desktop{margin-bottom: 8px !important;}
        .u-mb-xs-desktop{margin-bottom: 12px !important;}
        .u-mb-s-desktop{margin-bottom: 16px !important;}
        .u-mb-m-desktop{margin-bottom: 20px !important;}
        .u-mb-l-desktop{margin-bottom: 24px !important;}
        .u-mb-xl-desktop{margin-bottom: 32px !important;}
        .u-mb-xxl-desktop{margin-bottom: 48px !important;}
        .u-mb-xxxl-desktop{margin-bottom: 64px !important;}
        .u-mb-xxxxl-desktop{margin-bottom: 96px !important;}
    }

// MARGIN LEFT DESKTOP

    .u-ml-xxxxs-desktop{margin-left: 0 !important;}
    .u-ml-xxxs-desktop{margin-left: 0 !important;}
    .u-ml-xxs-desktop{margin-left: 0 !important;}
    .u-ml-xs-desktop{margin-left: 0 !important;}
    .u-ml-s-desktop{margin-left: 0 !important;}
    .u-ml-m-desktop{margin-left: 0 !important;}
    .u-ml-l-desktop{margin-left: 0 !important;}
    .u-ml-xl-desktop{margin-left: 0 !important;}
    .u-ml-xxl-desktop{margin-left: 0 !important;}
    .u-ml-xxxl-desktop{margin-left: 0 !important;}
    .u-ml-xxxxl-desktop{margin-left: 0 !important;}

    @media(min-width:992px) {
    
    .u-ml-xxxxs-desktop{margin-left: 2px !important;}
    .u-ml-xxxs-desktop{margin-left: 4px !important;}
    .u-ml-xxs-desktop{margin-left: 8px !important;}
    .u-ml-xs-desktop{margin-left: 12px !important;}
    .u-ml-s-desktop{margin-left: 16px !important;}
    .u-ml-m-desktop{margin-left: 20px !important;}
    .u-ml-l-desktop{margin-left: 24px !important;}
    .u-ml-xl-desktop{margin-left: 32px !important;}
    .u-ml-xxl-desktop{margin-left: 48px !important;}
    .u-ml-xxxl-desktop{margin-left: 64px !important;}
    .u-ml-xxxxl-desktop{margin-left: 96px !important;}
}


// MARGIN RIGHT DESKTOP

    .u-mr-xxxxs-desktop{margin-right: 0 !important;}
    .u-mr-xxxs-desktop{margin-right: 0 !important;}
    .u-mr-xxs-desktop{margin-right: 0 !important;}
    .u-mr-xs-desktop{margin-right: 0 !important;}
    .u-mr-s-desktop{margin-right: 0 !important;}
    .u-mr-m-desktop{margin-right: 0 !important;}
    .u-mr-l-desktop{margin-right: 0 !important;}
    .u-mr-xl-desktop{margin-right: 0 !important;}
    .u-mr-xxl-desktop{margin-right: 0 !important;}
    .u-mr-xxxl-desktop{margin-right: 0 !important;}
    .u-mr-xxxxl-desktop{margin-right: 0 !important;}

    @media(min-width:992px) {
    
    .u-mr-xxxxs-desktop{margin-right: 2px !important;}
    .u-mr-xxxs-desktop{margin-right: 4px !important;}
    .u-mr-xxs-desktop{margin-right: 8px !important;}
    .u-mr-xs-desktop{margin-right: 12px !important;}
    .u-mr-s-desktop{margin-right: 16px !important;}
    .u-mr-m-desktop{margin-right: 20px !important;}
    .u-mr-l-desktop{margin-right: 24px !important;}
    .u-mr-xl-desktop{margin-right: 32px !important;}
    .u-mr-xxl-desktop{margin-right: 48px !important;}
    .u-mr-xxxl-desktop{margin-right: 64px !important;}
    .u-mr-xxxxl-desktop{margin-right: 96px !important;}
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////// PADDING /////////////////////////////////// 
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

// PADDING RESET

.u-p-0{padding: 0 !important;}
.u-pt-0{padding-top:0 !important}
.u-pl-0{padding-left: 0 !important;}
.u-pr-0{padding-right:0 !important;}
.u-pb-0{padding-bottom: 0 !important;}


// PADDING (TOP / BOTTOM / LEFT / RIGHT)

.u-p-xxxxs{padding: 2px !important;}
.u-p-xxxs{padding: 4px !important;}
.u-p-xxs{padding: 8px !important;}
.u-p-xs{padding: 12px !important;}
.u-p-s{padding: 16px !important;}
.u-p-m{padding: 20px !important;}
.u-p-l{padding: calc(24px / 2) !important;}
.u-p-xl{padding: calc(32px / 2) !important;}
.u-p-xxl{padding: calc(48px / 2)  !important;}
.u-p-xxxl{padding: calc(64px / 2)  !important;}
.u-p-xxxxl{padding: calc(96px / 2)  !important;}


@media(min-width:768px) {
    .u-p-xxxxs{padding: 2px !important;}
    .u-p-xxxs{padding: 4px !important;}
    .u-p-xxs{padding: 8px !important;}
    .u-p-xs{padding: 12px !important;}
    .u-p-s{padding: 16px !important;}
    .u-p-m{padding: 20px !important;}
    .u-p-l{padding: calc(24px / 1.5) !important;}
    .u-p-xl{padding: calc(32px / 1.5) !important;}
    .u-p-xxl{padding: calc(48px / 1.5)  !important;}
    .u-p-xxxl{padding: calc(64px / 1.5)  !important;}
    .u-p-xxxxl{padding: calc(96px / 1.5)  !important;}
}

@media(min-width:992px) {
    .u-p-xxxxs{padding: 2px !important;}
    .u-p-xxxs{padding: 4px !important;}
    .u-p-xxs{padding: 8px !important;}
    .u-p-xs{padding: 12px !important;}
    .u-p-s{padding: 16px !important;}
    .u-p-m{padding: 20px !important;}
    .u-p-l{padding: 24px !important;}
    .u-p-xl{padding: 32px !important;}
    .u-p-xxl{padding: 48px !important;}
    .u-p-xxxl{padding: 64px !important;}
    .u-p-xxxxl{padding: 96px !important;}
}

// PADDING TOP



.u-pt-xxxxs{padding-top: 2px !important;}
.u-pt-xxxs{padding-top: 4px !important;}
.u-pt-xxs{padding-top: 8px !important;}
.u-pt-xs{padding-top: 12px !important;}
.u-pt-s{padding-top: 16px !important;}
.u-pt-m{padding-top: 20px !important;}
.u-pt-l{padding-top: calc(24px / 2) !important;}
.u-pt-xl{padding-top: calc(32px / 2) !important;}
.u-pt-xxl{padding-top: calc(48px / 2)  !important;}
.u-pt-xxxl{padding-top: calc(64px / 2)  !important;}
.u-pt-xxxxl{padding-top: calc(96px / 2)  !important;}


@media(min-width:768px) {
    .u-pt-xxxxs{padding-top: 2px !important;}
    .u-pt-xxxs{padding-top: 4px !important;}
    .u-pt-xxs{padding-top: 8px !important;}
    .u-pt-xs{padding-top: 12px !important;}
    .u-pt-s{padding-top: 16px !important;}
    .u-pt-m{padding-top: 20px !important;}
    .u-pt-l{padding-top: calc(24px / 1.5) !important;}
    .u-pt-xl{padding-top: calc(32px / 1.5) !important;}
    .u-pt-xxl{padding-top: calc(48px / 1.5)  !important;}
    .u-pt-xxxl{padding-top: calc(64px / 1.5)  !important;}
    .u-pt-xxxxl{padding-top: calc(96px / 1.5)  !important;}
}

@media(min-width:992px) {
    .u-pt-xxxxs{padding-top: 2px !important;}
    .u-pt-xxxs{padding-top: 4px !important;}
    .u-pt-xxs{padding-top: 8px !important;}
    .u-pt-xs{padding-top: 12px !important;}
    .u-pt-s{padding-top: 16px !important;}
    .u-pt-m{padding-top: 20px !important;}
    .u-pt-l{padding-top: 24px !important;}
    .u-pt-xl{padding-top: 32px !important;}
    .u-pt-xxl{padding-top: 48px !important;}
    .u-pt-xxxl{padding-top: 64px !important;}
    .u-pt-xxxxl{padding-top: 96px !important;}
}

// PADDING BOTTOM



.u-pb-xxxxs{padding-bottom: 2px !important;}
.u-pb-xxxs{padding-bottom: 4px !important;}
.u-pb-xxs{padding-bottom: 8px !important;}
.u-pb-xs{padding-bottom: 12px !important;}
.u-pb-s{padding-bottom: 16px !important;}
.u-pb-m{padding-bottom: 20px !important;}
.u-pb-l{padding-bottom: calc(24px / 2) !important;}
.u-pb-xl{padding-bottom: calc(32px / 2) !important;}
.u-pb-xxl{padding-bottom: calc(48px / 2)  !important;}
.u-pb-xxxl{padding-bottom: calc(64px / 2)  !important;}
.u-pb-xxxxl{padding-bottom: calc(96px / 2)  !important;}


@media(min-width:768px) {
    .u-pb-xxxxs{padding-bottom: 2px !important;}
    .u-pb-xxxs{padding-bottom: 4px !important;}
    .u-pb-xxs{padding-bottom: 8px !important;}
    .u-pb-xs{padding-bottom: 12px !important;}
    .u-pb-s{padding-bottom: 16px !important;}
    .u-pb-m{padding-bottom: 20px !important;}
    .u-pb-l{padding-bottom: calc(24px / 1.5) !important;}
    .u-pb-xl{padding-bottom: calc(32px / 1.5) !important;}
    .u-pb-xxl{padding-bottom: calc(48px / 1.5)  !important;}
    .u-pb-xxxl{padding-bottom: calc(64px / 1.5)  !important;}
    .u-pb-xxxxl{padding-bottom: calc(96px / 1.5)  !important;}
}

@media(min-width:992px) {
    .u-pb-xxxxs{padding-bottom: 2px !important;}
    .u-pb-xxxs{padding-bottom: 4px !important;}
    .u-pb-xxs{padding-bottom: 8px !important;}
    .u-pb-xs{padding-bottom: 12px !important;}
    .u-pb-s{padding-bottom: 16px !important;}
    .u-pb-m{padding-bottom: 20px !important;}
    .u-pb-l{padding-bottom: 24px !important;}
    .u-pb-xl{padding-bottom: 32px !important;}
    .u-pb-xxl{padding-bottom: 48px !important;}
    .u-pb-xxxl{padding-bottom: 64px !important;}
    .u-pb-xxxxl{padding-bottom: 96px !important;}
}


// PADDING LEFT

.u-pl-xxxxs{padding-left: 2px !important;}
.u-pl-xxxs{padding-left: 4px !important;}
.u-pl-xxs{padding-left: 8px !important;}
.u-pl-xs{padding-left: 12px !important;}
.u-pl-s{padding-left: 16px !important;}
.u-pl-m{padding-left: 20px !important;}
.u-pl-l{padding-left: 24px !important;}
.u-pl-xl{padding-left: calc(32px / 2) !important;}
.u-pl-xxl{padding-left: calc(48px / 2) !important;}
.u-pl-xxxl{padding-left: calc(64px / 2) !important;}
.u-pl-xxxxl{padding-left: calc(96px / 2) !important;}


@media(min-width:992px) {
    .u-pl-xxxxs{padding-left: 2px !important;}
    .u-pl-xxxs{padding-left: 4px !important;}
    .u-pl-xxs{padding-left: 8px !important;}
    .u-pl-xs{padding-left: 12px !important;}
    .u-pl-s{padding-left: 16px !important;}
    .u-pl-m{padding-left: 20px !important;}
    .u-pl-l{padding-left: 24px !important;}
    .u-pl-xl{padding-left: 32px !important;}
    .u-pl-xxl{padding-left: 48px !important;}
    .u-pl-xxxl{padding-left: 64px !important;}
    .u-pl-xxxxl{padding-left: 96px !important;}
}


// PADDING RIGHT

.u-pr-xxxxs{padding-right: 2px !important;}
.u-pr-xxxs{padding-right: 4px !important;}
.u-pr-xxs{padding-right: 8px !important;}
.u-pr-xs{padding-right: 12px !important;}
.u-pr-s{padding-right: 16px !important;}
.u-pr-m{padding-right: 20px !important;}
.u-pr-l{padding-right: 24px !important;}
.u-pr-xl{padding-right: 32px !important;}
.u-pr-xxl{padding-right: 48px !important;}
.u-pr-xxxl{padding-right: 64px !important;}
.u-pr-xxxxl{padding-right: 96px !important;}


// PADDING (LEFT / RIGHT)

// PADDING (LEFT / RIGHT)

.u-px-xxxxs{padding: 0 2px 0 2px !important;}
.u-px-xxxs{padding: 0 4px 0 4px !important;}
.u-px-xxs{padding: 0 8px 0 8px !important;}
.u-px-xs{padding: 0 12px 0 12px !important;}
.u-px-s{padding: 0 16px 0 16px !important;}
.u-px-m{padding: 0 20px 0 20px !important;}
.u-px-l{padding: 0 24px 0 24px !important;}
.u-px-xl{padding: 0 32px 0 32px !important;}
.u-px-xxl{padding: 0 48px 0 48px !important;}
.u-px-xxxl{padding: 0 64px 0 64px !important;}
.u-px-xxxxl{padding: 0 96px 0 96px !important;}


// PADDING (LEFT / RIGHT)

.u-px-xxxxs{padding:  0 2px 0 2px !important;}
.u-px-xxxs{padding:  0 4px 0 4px !important;}
.u-px-xxs{padding:  0 8px 0 8px !important;}
.u-px-xs{padding:  0 12px 0 12px !important;}
.u-px-s{padding:  0 16px 0 16px !important;}
.u-px-m{padding:  0 20px 0 20px !important;}
.u-px-l{padding:  0 24px 0 24px !important;}
.u-px-xl{padding:  0 calc(32px / 2) 0 calc(32px / 2)  !important;}
.u-px-xxl{padding:  0 calc(48px / 2)  0 calc(48px / 2) !important;}
.u-px-xxxl{padding:  0 calc(64px / 2)  0 calc(64px / 2) !important;}
.u-px-xxxxl{padding:  0 calc(96px / 2)  0 calc(96px / 2) !important;}


@media(min-width:768px) {
    .u-px-xxxxs{padding:  0 2px 0 2px !important;}
    .u-px-xxxs{padding:  0 4px 0 4px !important;}
    .u-px-xxs{padding:  0 8px 0 8px !important;}
    .u-px-xs{padding:  0 12px 0 12px !important;}
    .u-px-s{padding:  0 16px 0 16px !important;}
    .u-px-m{padding:  0 20px 0 20px !important;}
    .u-px-l{padding:  0 24px 0 24px !important;}
    .u-px-xl{padding:  0 calc(32px / 1.5) 0 calc(32px / 1.5)  !important;}
    .u-px-xxl{padding:  0 calc(48px / 1.5)  0 calc(48px / 1.5) !important;}
    .u-px-xxxl{padding:  0 calc(64px / 1.5)  0 calc(64px / 1.5) !important;}
    .u-px-xxxxl{padding:  0 calc(96px / 1.5)  0 calc(96px / 1.5) !important;}
}

@media(min-width:992px) {
    .u-px-xxxxs{padding:  0 2px 0 2px !important;}
    .u-px-xxxs{padding:  0 4px 0 4px !important;}
    .u-px-xxs{padding:  0 8px 0 8px !important;}
    .u-px-xs{padding:  0 12px 0 12px !important;}
    .u-px-s{padding:  0 16px 0 16px !important;}
    .u-px-m{padding:  0 20px 0 20px !important;}
    .u-px-l{padding:  0 24px 0 24px !important;}
    .u-px-xl{padding:  0 32px 0 32px !important;}
    .u-px-xxl{padding:  0 48px 0 48px !important;}
    .u-px-xxxl{padding:  0 64px 0 64px !important;}
    .u-px-xxxxl{padding:  0 96px 0 96px !important;}
}


// PADDING (TOP / BOTTOM)


.u-py-xxxxs{padding: 2px 0 2px 0 !important;}
.u-py-xxxs{padding: 4px 0 4px 0 !important;}
.u-py-xxs{padding: 8px 0 8px 0 !important;}
.u-py-xs{padding: 12px 0 12px 0 !important;}
.u-py-s{padding: 16px 0 16px 0 !important;}
.u-py-m{padding: 20px 0 20px 0 !important;}
.u-py-l{padding: 24px 0 24px 0 !important;}
.u-py-xl{padding: calc(32px / 2) 0 calc(32px / 2) 0 !important;}
.u-py-xxl{padding: calc(48px / 2) 0 calc(48px / 2)  0 !important;}
.u-py-xxxl{padding: calc(64px / 2) 0 calc(64px / 2)  0 !important;}
.u-py-xxxxl{padding: calc(96px / 2) 0 calc(96px / 2)  0 !important;}


@media(min-width:768px) {
    .u-py-xxxxs{padding: 2px 0 2px 0 !important;}
    .u-py-xxxs{padding: 4px 0 4px 0 !important;}
    .u-py-xxs{padding: 8px 0 8px 0 !important;}
    .u-py-xs{padding: 12px 0 12px 0 !important;}
    .u-py-s{padding: 16px 0 16px 0 !important;}
    .u-py-m{padding: 20px 0 20px 0 !important;}
    .u-py-l{padding: 24px 0 24px 0 !important;}
    .u-py-xl{padding: calc(32px / 1.5) 0 calc(32px / 1.5) 0 !important;}
    .u-py-xxl{padding: calc(48px / 1.5) 0 calc(48px / 1.5)  0 !important;}
    .u-py-xxxl{padding: calc(64px / 1.5) 0 calc(64px / 1.5)  0 !important;}
    .u-py-xxxxl{padding: calc(96px / 1.5) 0 calc(96px / 1.5)  0 !important;}
}

@media(min-width:992px) {
    .u-py-xxxxs{padding: 2px 0 2px 0 !important;}
    .u-py-xxxs{padding: 4px 0 4px 0 !important;}
    .u-py-xxs{padding: 8px 0 8px 0 !important;}
    .u-py-xs{padding: 12px 0 12px 0 !important;}
    .u-py-s{padding: 16px 0 16px 0 !important;}
    .u-py-m{padding: 20px 0 20px 0 !important;}
    .u-py-l{padding: 24px 0 24px 0 !important;}
    .u-py-xl{padding: 32px 0 32px 0 !important;}
    .u-py-xxl{padding: 48px 0 48px 0 !important;}
    .u-py-xxxl{padding: 64px 0 64px 0 !important;}
    .u-py-xxxxl{padding: 96px 0 96px 0 !important;}
}




/////////////////////////////////////////////////////////// RESPONSIVE PADDING

// PADDING RIGHT DESKTOP

.u-pr-xxxxs-desktop{padding-right: 0 !important;}
.u-pr-xxxs-desktop{padding-right: 0 !important;}
.u-pr-xxs-desktop{padding-right: 0 !important;}
.u-pr-xs-desktop{padding-right: 0 !important;}
.u-pr-s-desktop{padding-right: 0 !important;}
.u-pr-m-desktop{padding-right: 0 !important;}
.u-pr-l-desktop{padding-right: 0 !important;}
.u-pr-xl-desktop{padding-right: 0 !important;}
.u-pr-xxl-desktop{padding-right: 0 !important;}
.u-pr-xxxl-desktop{padding-right: 0 !important;}
.u-pr-xxxxl-desktop{padding-right: 0 !important;}

@media(min-width:992px) {

        .u-pr-xxxxs-desktop{padding-right: 2px !important;}
        .u-pr-xxxs-desktop{padding-right: 4px !important;}
        .u-pr-xxs-desktop{padding-right: 8px !important;}
        .u-pr-xs-desktop{padding-right: 12px !important;}
        .u-pr-s-desktop{padding-right: 16px !important;}
        .u-pr-m-desktop{padding-right: 20px !important;}
        .u-pr-l-desktop{padding-right: 24px !important;}
        .u-pr-xl-desktop{padding-right: 32px !important;}
        .u-pr-xxl-desktop{padding-right: 48px !important;}
        .u-pr-xxxl-desktop{padding-right: 64px !important;}
        .u-pr-xxxxl-desktop{padding-right: 96px !important;}
        
    }
    


// PADDING LEFT DESKTOP

.u-pl-xxxxs-desktop{padding-left: 0 !important;}
.u-pl-xxxs-desktop{padding-left: 0 !important;}
.u-pl-xxs-desktop{padding-left: 0 !important;}
.u-pl-xs-desktop{padding-left: 0 !important;}
.u-pl-s-desktop{padding-left: 0 !important;}
.u-pl-m-desktop{padding-left: 0 !important;}
.u-pl-l-desktop{padding-left: 0 !important;}
.u-pl-xl-desktop{padding-left: 0 !important;}
.u-pl-xxl-desktop{padding-left: 0 !important;}
.u-pl-xxxl-desktop{padding-left: 0 !important;}
.u-pl-xxxxl-desktop{padding-left: 0 !important;}


@media(min-width:992px) {

        .u-pl-xxxxs-desktop{padding-left: 2px !important;}
        .u-pl-xxxs-desktop{padding-left: 4px !important;}
        .u-pl-xxs-desktop{padding-left: 8px !important;}
        .u-pl-xs-desktop{padding-left: 12px !important;}
        .u-pl-s-desktop{padding-left: 16px !important;}
        .u-pl-m-desktop{padding-left: 20px !important;}
        .u-pl-l-desktop{padding-left: 24px !important;}
        .u-pl-xl-desktop{padding-left: 32px !important;}
        .u-pl-xxl-desktop{padding-left: 48px !important;}
        .u-pl-xxxl-desktop{padding-left: 64px !important;}
        .u-pl-xxxxl-desktop{padding-left: 96px !important;}
        
    }



    // PADDING TOP DESKTOP

.u-pt-xxxxs-desktop{padding-top: 0 !important;}
.u-pt-xxxs-desktop{padding-top: 0 !important;}
.u-pt-xxs-desktop{padding-top: 0 !important;}
.u-pt-xs-desktop{padding-top: 0 !important;}
.u-pt-s-desktop{padding-top: 0 !important;}
.u-pt-m-desktop{padding-top: 0 !important;}
.u-pt-l-desktop{padding-top: 0 !important;}
.u-pt-xl-desktop{padding-top: 0 !important;}
.u-pt-xxl-desktop{padding-top: 0 !important;}
.u-pt-xxxl-desktop{padding-top: 0 !important;}
.u-pt-xxxxl-desktop{padding-top: 0 !important;}

@media(min-width:992px) {

    .u-pt-xxxxs-desktop{padding-top: 2px !important;}
    .u-pt-xxxs-desktop{padding-top: 4px !important;}
    .u-pt-xxs-desktop{padding-top: 8px !important;}
    .u-pt-xs-desktop{padding-top: 12px !important;}
    .u-pt-s-desktop{padding-top: 16px !important;}
    .u-pt-m-desktop{padding-top: 20px !important;}
    .u-pt-l-desktop{padding-top: 24px !important;}
    .u-pt-xl-desktop{padding-top: 32px !important;}
    .u-pt-xxl-desktop{padding-top: 48px !important;}
    .u-pt-xxxl-desktop{padding-top: 64px !important;}
    .u-pt-xxxxl-desktop{padding-top: 96px !important;}
    
}

// PADDING RIGHT RESPONSIVE

    .u-pr-xxxxs-responsive{padding-right: 2px !important;}
    .u-pr-xxxs-responsive{padding-right: 4px !important;}
    .u-pr-xxs-responsive{padding-right: 8px !important;}
    .u-pr-xs-responsive{padding-right: 12px !important;}
    .u-pr-s-responsive{padding-right: 16px !important;}
    .u-pr-m-responsive{padding-right: 20px !important;}
    .u-pr-l-responsive{padding-right: 24px !important;}
    .u-pr-xl-responsive{padding-right: 32px !important;}
    .u-pr-xxl-responsive{padding-right: 48px !important;}
    .u-pr-xxxl-responsive{padding-right: 64px !important;}
    .u-pr-xxxxl-responsive{padding-right: 96px !important;}


@media(min-width:992px) {
    
    .u-pr-xxxxs-responsive{padding-right: 0 !important;}
    .u-pr-xxxs-responsive{padding-right: 0 !important;}
    .u-pr-xxs-responsive{padding-right: 0 !important;}
    .u-pr-xs-responsive{padding-right: 0 !important;}
    .u-pr-s-responsive{padding-right: 0 !important;}
    .u-pr-m-responsive{padding-right: 0 !important;}
    .u-pr-l-responsive{padding-right: 0 !important;}
    .u-pr-xl-responsive{padding-right: 0 !important;}
    .u-pr-xxl-responsive{padding-right: 0 !important;}
    .u-pr-xxxl-responsive{padding-right: 0 !important;}
    .u-pr-xxxxl-responsive{padding-right: 0 !important;}

}

// PADDING LEFT RESPONSIVE

.u-pl-xxxxs-responsive{padding-left: 2px !important;}
.u-pl-xxxs-responsive{padding-left: 4px !important;}
.u-pl-xxs-responsive{padding-left: 8px !important;}
.u-pl-xs-responsive{padding-left: 12px !important;}
.u-pl-s-responsive{padding-left: 16px !important;}
.u-pl-m-responsive{padding-left: 20px !important;}
.u-pl-l-responsive{padding-left: 24px !important;}
.u-pl-xl-responsive{padding-left: 32px !important;}
.u-pl-xxl-responsive{padding-left: 48px !important;}
.u-pl-xxxl-responsive{padding-left: 64px !important;}
.u-pl-xxxxl-responsive{padding-left: 96px !important;}


@media(min-width:992px) {

.u-pl-xxxxs-responsive{padding-left: 0 !important;}
.u-pl-xxxs-responsive{padding-left: 0 !important;}
.u-pl-xxs-responsive{padding-left: 0 !important;}
.u-pl-xs-responsive{padding-left: 0 !important;}
.u-pl-s-responsive{padding-left: 0 !important;}
.u-pl-m-responsive{padding-left: 0 !important;}
.u-pl-l-responsive{padding-left: 0 !important;}
.u-pl-xl-responsive{padding-left: 0 !important;}
.u-pl-xxl-responsive{padding-left: 0 !important;}
.u-pl-xxxl-responsive{padding-left: 0 !important;}
.u-pl-xxxxl-responsive{padding-left: 0 !important;}

}


// MARGIN + DETAIL

.u-pb-xxxxl .detail.detail-green--8 img{
    bottom: -85px;
}

.text-legal {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 150px;
    font-size: 16px;
    word-wrap: break-word;

    h1,h2,h3,h4 {
        line-height: 30px;
    }
}