

@import
'components/molecules/general',
'components/molecules/alert';

 
@import
'./node_modules/slick-carousel/slick/slick-theme.scss',
'./node_modules/slick-carousel/slick/slick.scss';
 
/*
@charset 'UTF-8';

/// ⚠️ This block requires this specific ORDER, not change the order!
@import
    'abstracts/specs',
    'abstracts/functions',
    'abstracts/generators',
    'abstracts/variables',
    'abstracts/mixins';


@import
    'vendor/normalize',
    './node_modules/swiper/swiper',
    './node_modules/swiper/components/a11y/a11y',
    // './node_modules/swiper/components/controller/controller',
    // './node_modules/swiper/components/effect-coverflow/effect-coverflow',
    // './node_modules/swiper/components/effect-cube/effect-cube',
    './node_modules/swiper/components/effect-fade/effect-fade',
    // './node_modules/swiper/components/effect-flip/effect-flip',
    // './node_modules/swiper/components/lazy/lazy',
    './node_modules/swiper/components/navigation/navigation',
    './node_modules/swiper/components/pagination/pagination',
    './node_modules/select2/src/scss/core',
    //'./node_modules/dropzone/src/dropzone',
    './node_modules/photoswipe/src/css/main',
    './vendor/jquery-ui-sortable/jquery-ui',
    './vendor/modulobox/css/modulobox';
    // './node_modules/swiper/components/scrollbar/scrollbar',
    // './node_modules/swiper/components/thumbs/thumbs',
    // './node_modules/swiper/components/zoom/zoom';


// Bootstrap 4 Grid
// This file include itself all the required stuff to
// get fully BS4 grid features
@import
    './node_modules/bootstrap/scss/bootstrap-grid',
    './node_modules/bootstrap/scss/mixins/hover',
    './node_modules/bootstrap/scss/mixins/table-row',
    './node_modules/bootstrap/scss/tables',
    './node_modules/bootstrap/scss/utilities/sizing';


@import
    'vendor/customization/bootstrap-custom',
    'vendor/customization/swiper-custom',
    'vendor/customization/photoswipe-custom',
    'vendor/customization/dropzone';


@import
    'base/base',
    'base/color',
    'base/fonts',
    'base/form-normalize',
    'base/misc',
    'base/space',
    'base/typography';


/// Layout components
@import
    'layout/animations',
    // 'layout/aligned-box',
    // 'layout/aside-fullwidth-layout',
    // 'layout/aside-cta-layout',
    // 'layout/aside-layout',
    'layout/backdrop',
    'layout/bar',
    'layout/bar-new',
    // 'layout/bulletin-card-grid',
    // 'layout/column-layout',
    // 'layout/edge-section',
    // 'layout/float-box-container',
    'layout/footer',
    'layout/footer-mobile',
    'layout/fullwidth-breaker',
    // 'layout/grid',
    // 'layout/halfs-container',
    // 'layout/halfs',
    'layout/header',
    'layout/header-mobile',
    'layout/header-new',
    // 'layout/modal',
    // 'layout/nts-grid',
    // 'layout/page-wrapper',
    // 'layout/promo',
    'layout/popup',
    'layout/pusher';
    // 'layout/scroll-area',
    // 'layout/services-card-grid',
    // 'layout/sloped';


/// Components → Atoms
@import
    // 'components/atoms/alert',
    // 'components/atoms/avatar',
    // 'components/atoms/back-to',
    // 'components/atoms/badge',
    // 'components/atoms/btn-ghost',
    // 'components/atoms/btn-link',
    // 'components/atoms/btn-op',
     'components/atoms/btn',
     'components/atoms/checkbox',
     'components/atoms/custom-scrollbar',
    // 'components/atoms/chip',
    'components/atoms/datepicker',
    // 'components/atoms/close-btn',
    // 'components/atoms/form-control',
    // 'components/atoms/form-upload',
    'components/atoms/hamburger',
    'components/atoms/icon',
    'components/atoms/input-number',
    // 'components/atoms/img-rotator',
    'components/atoms/image',
    'components/atoms/link', 
    'components/atoms/misc', 
    // 'components/atoms/map',
    // 'components/atoms/post-category',
    // 'components/atoms/post-date',
    // 'components/atoms/preloader',
    // 'components/atoms/progress',
    // 'components/atoms/radio',
    // 'components/atoms/rating',
    // 'components/atoms/search-results',
    // 'components/atoms/social-icon-list',
    'components/atoms/switch',
    // 'components/atoms/tabs',
    // 'components/atoms/tag', 
    'components/atoms/text',
    // 'components/atoms/comments';
    // 'components/atoms/to-top',
    // 'components/atoms/tooltip',
    // 'components/atoms/video-bg',
    'components/atoms/radiobutton',
    'components/atoms/timepicker',
    'components/atoms/video',
    'components/atoms/multifile';


/// Components → Molecules
@import
    'components/molecules/account',
    'components/molecules/ad',
    // 'components/molecules/accordion',
    // 'components/molecules/advert-form',
    // 'components/molecules/banner',
    'components/molecules/breadcrumb',
    'components/molecules/blog',
    'components/molecules/cms-custom',
    // 'components/molecules/bulletin-card',
    // 'components/molecules/bulletin-cta',
    // 'components/molecules/bulletin-info',
    // 'components/molecules/bulletin-list',
    // 'components/molecules/business-highlights',
    // 'components/molecules/business-list',
    // 'components/molecules/call-us',
    // 'components/molecules/card-carousel',
    // 'components/molecules/card-infos',
    // 'components/molecules/card',
    'components/molecules/card',
    // 'components/molecules/carousel',
    // 'components/molecules/chart-cta',
    'components/molecules/cookies',
    // 'components/molecules/download-attached',
    // 'components/molecules/chip-list',
    // 'components/molecules/dropdown',
    // 'components/molecules/equipment-carousel',
    // 'components/molecules/equipment-logo-carousel',
    // 'components/molecules/faqs',
    // 'components/molecules/faqs-list',
    // 'components/molecules/features-list',
    'components/molecules/features',
    'components/molecules/filter',
    // 'components/molecules/float-box',
    'components/molecules/form',
    'components/molecules/gallery',
    'components/molecules/gallery-mobile',
    'components/molecules/histogram',
    // 'components/molecules/hero-slide',
    // 'components/molecules/highlighted-cta',
    // 'components/molecules/html-content',
    // 'components/molecules/html-content-modal',
    // 'components/molecules/installation-list',
    // 'components/molecules/interest-list',
    // 'components/molecules/intro',
    'components/molecules/icon-text',
    'components/molecules/image-info',
    'components/molecules/info',
    'components/molecules/label',
    // 'components/molecules/link-list',
    'components/molecules/list',
    'components/molecules/more-info',
    'components/molecules/nav-click',
    'components/molecules/nav-main',
    'components/molecules/nav-main-v2',
    'components/molecules/nav-simple',
    'components/molecules/nav-vertical',
    'components/molecules/nav-responsive',
    'components/molecules/menu',
    'components/molecules/menu-drop',
    // 'components/molecules/main-new',
    // 'components/molecules/main-news',
    'components/molecules/map',
    // 'components/molecules/map-info-window',
    // 'components/molecules/mini-gallery',
    'components/molecules/mobile-menu',
    'components/molecules/nav-mobile',
    // 'components/molecules/more-services',
    // 'components/molecules/newsletter',
    // 'components/molecules/newsletter-promo',
    'components/molecules/user',
    // 'components/molecules/packs-list',
    // 'components/molecules/paginator',
    'components/molecules/pagination',
    // 'components/molecules/post-detail',
    // 'components/molecules/post-mini',
    'components/molecules/price',
    // 'components/molecules/related-list',
    // 'components/molecules/search-box',
    'components/molecules/search',
    'components/molecules/select2',
    // 'components/molecules/secondary-nav',
    // 'components/molecules/section-lists',
    // 'components/molecules/service',
    // 'components/molecules/service-carousel',
    // 'components/molecules/service-contact', 
    // 'components/molecules/service-content',
    // 'components/molecules/service-feature',
    // 'components/molecules/service-features',
    // 'components/molecules/service-list',
    // 'components/molecules/services-card',
    // 'components/molecules/services-cta',
    // 'components/molecules/services-premium', 
    // 'components/molecules/services-goto',
    // 'components/molecules/services-hero',
    'components/molecules/swiper',
    'components/molecules/show-more',
    'components/molecules/state', 
    'components/molecules/steps',
    'components/molecules/sidebar',
    'components/molecules/survey',
    // 'components/molecules/table-card',
    // 'components/molecules/table',
    // 'components/molecules/tabs-slider',
    // 'components/molecules/tag-ranking',
    'components/molecules/tabs',
    'components/molecules/text-image',
    'components/molecules/text-module',
    'components/molecules/title',
    'components/molecules/tooltip',
    'components/molecules/tracing',
    'components/molecules/ad',
    // 'components/molecules/toast';
    'components/molecules/video';



/// Pages
// @import
    // 'pages/demo-components',
    // 'pages/color-system',
    // 'pages/space-system',
    // 'pages/type-system',
    // 'pages/tests';


/// Legacy
// @import
//     'legacy/sample';


// Global modifiers
@import
    'modifiers/is-ios',
    'modifiers/navigation';


@import
    'utilities/absolut-positioning',
    'utilities/align',
    'utilities/border',
    'utilities/float',
    'utilities/height',
    'utilities/lazyload',
    'utilities/misc',
    'utilities/ratio-box',
    'utilities/responsive',
    'utilities/text-manage',
    'utilities/width';


    */