@charset "UTF-8";
body {
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Poppins'; }
  @media (min-width: 992px) {
    body {
      font-size: 20px;
      line-height: 24px; }
      body .privacity_label {
        font-size: 18px; }
      body .privacity_text {
        font-size: 12px; }
      body .privacity_checkbox {
        width: 30px;
        height: 30px; } }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

a {
  color: inherit;
  text-decoration: none; }

p {
  margin: 0; }

.cookies {
  background-color: white;
  font-size: 14px;
  line-height: 19px;
  width: calc(100% - 80px);
  max-width: 400px;
  padding: 20px;
  bottom: 20px;
  position: fixed;
  right: 20px;
  box-shadow: #00000014 0 0 22px;
  z-index: 30; }
  @media (min-width: 768px) {
    .cookies {
      width: calc(100% - 100px);
      padding: 30px;
      font-size: 16px;
      line-height: 22px; } }

.cookies_buttons {
  display: flex;
  justify-content: space-between; }

.privacity_label {
  font-size: 18px; }

.privacity_text {
  font-size: 10px;
  margin-bottom: 20px; }

.privacity_checkbox {
  width: 70px;
  height: 70px; }

.form__check {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }

.show_cookies {
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 99; }

.text-size--xxxl {
  font-size: 56px;
  line-height: 56px; }
  @media (min-width: 992px) {
    .text-size--xxxl {
      font-size: 96px;
      line-height: 96px; } }

.text-size--xl {
  font-size: 46px;
  line-height: 46px; }
  @media (min-width: 992px) {
    .text-size--xl {
      font-size: 72px;
      line-height: 72px; } }

.text-size--l {
  font-size: 32px;
  line-height: 45px; }
  @media (min-width: 992px) {
    .text-size--l {
      font-size: 56px;
      line-height: 67px; } }

.text-size--ml {
  font-size: 28px;
  line-height: 33px; }
  @media (min-width: 992px) {
    .text-size--ml {
      font-size: 40px;
      line-height: 48px; } }

.text-size--m {
  font-size: 23p;
  line-height: 34px; }
  @media (min-width: 992px) {
    .text-size--m {
      font-size: 30px;
      line-height: 42px; } }

.text-size--xs {
  font-size: 14px;
  line-height: 16px; }

.text-family--cocogoose {
  font-family: 'Cocogoose Classic'; }

.text-style--bold {
  font-weight: 500; }

.text-style--underline {
  text-decoration: underline; }

.text-gradient--blue {
  background: linear-gradient(to right, #7ffed1 0%, #44d0fe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.modal {
  display: none;
  position: fixed;
  z-index: 8887;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #000000a1;
  transition: all 1s ease-in-out; }
  .modal-content {
    background: #FFF;
    margin: 10vh auto;
    overflow-y: auto;
    padding: 50px 10px 30px 10px;
    width: 90%;
    position: relative;
    border-radius: 20px; }
  .modal-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .modal-close {
    color: black;
    text-decoration: none;
    font-size: 38px;
    font-weight: 100;
    position: absolute;
    top: 20px;
    right: 20px; }
    .modal-close:hover, .modal-close:focus {
      text-decoration: none;
      cursor: pointer; }
  .modal-title {
    color: #FFF; }

@media (min-width: 1366px) {
  .modal-content {
    margin: 10vh auto;
    padding: 70px 50px;
    width: 30%; } }

.slick-next::before, .slick-prev::before {
  color: blue; }

.slick-prev {
  display: none !important; }

.slick-next {
  left: -30px;
  bottom: 0;
  top: auto; }

.slick-slide {
  padding: 0 15px 0  0; }

.slick-list {
  padding: 0 35% 0 0 !important; }

.slick-next:before {
  content: url(../img/arrow-right.svg) !important;
  position: absolute;
  top: 136px;
  right: 30px; }

.footer__menu {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 60px;
  color: white; }
  @media (min-width: 600px) {
    .footer__menu {
      flex-direction: row;
      margin-bottom: 120px; } }
  @media (min-width: 1150px) {
    .footer__menu {
      align-items: center; } }
  .footer__menu-left {
    width: 100%;
    text-align: center; }
    @media (min-width: 600px) {
      .footer__menu-left {
        width: 150px;
        text-align: left; } }
    @media (min-width: 1450px) {
      .footer__menu-left {
        width: 450px; } }
    .footer__menu-left img {
      height: 50px; }
      @media (min-width: 1450px) {
        .footer__menu-left img {
          height: inherit; } }
  .footer__menu-center {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 30px 0; }
    @media (min-width: 600px) {
      .footer__menu-center {
        width: calc(100% - 360px);
        padding: 0 0 0 40px;
        justify-content: flex-start; } }
    @media (min-width: 1150px) {
      .footer__menu-center {
        flex-direction: row;
        justify-content: flex-end;
        padding: 0; } }
    @media (min-width: 1450px) {
      .footer__menu-center {
        width: calc(100% - 650px); } }
    .footer__menu-center ul {
      display: flex;
      flex-direction: column;
      font-family: 'Cocogoose Classic'; }
      @media (min-width: 1150px) {
        .footer__menu-center ul {
          flex-direction: row; } }
      .footer__menu-center ul li {
        margin: 0 0 10px 0;
        text-align: center; }
        @media (min-width: 600px) {
          .footer__menu-center ul li {
            margin: 0 20px 0 0;
            text-align: left; } }
        .footer__menu-center ul li:last-child {
          margin-right: 0; }
        .footer__menu-center ul li a:hover {
          opacity: 0.8; }
  .footer__menu-right {
    width: 100%;
    display: flex;
    justify-content: center; }
    @media (min-width: 600px) {
      .footer__menu-right {
        width: 200px;
        justify-content: flex-end; } }

.footer__bottom {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #7FFED1; }
  @media (min-width: 1150px) {
    .footer__bottom {
      flex-direction: row;
      align-items: flex-end; } }
  .footer__bottom-left {
    width: 100%;
    text-align: center; }
    @media (min-width: 1150px) {
      .footer__bottom-left {
        width: 50%;
        text-align: left; } }
  .footer__bottom-right {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    justify-content: center;
    align-items: center; }
    @media (min-width: 768px) {
      .footer__bottom-right {
        flex-direction: row; } }
    @media (min-width: 1150px) {
      .footer__bottom-right {
        justify-content: flex-end;
        align-items: flex-end;
        width: 50%; } }
    .footer__bottom-right ul {
      display: flex;
      align-items: flex-end; }
      .footer__bottom-right ul li {
        margin: 15px 5px 15px 5px; }
        @media (min-width: 768px) {
          .footer__bottom-right ul li {
            margin: 0 40px 0 0; } }
        .footer__bottom-right ul li a:hover {
          opacity: 0.8; }
    .footer__bottom-right img {
      height: 31px; }

.img-detail.whois {
  top: 0; }
  @media (min-width: 992px) {
    .img-detail.whois {
      top: 170px; } }

.grid {
  display: flex;
  flex-direction: column; }
  @media (min-width: 992px) {
    .grid {
      flex-direction: row; } }
  .grid__mixed {
    display: flex;
    flex-direction: column; }
    @media (min-width: 992px) {
      .grid__mixed {
        flex-direction: row; } }
    .grid__mixed-left {
      width: 100%;
      padding: 0 0 0 0; }
      @media (min-width: 992px) {
        .grid__mixed-left {
          width: 35%;
          padding: 0 60px 0 0; } }
    .grid__mixed-right {
      width: 100%; }
      @media (min-width: 992px) {
        .grid__mixed-right {
          width: 65%; } }
  .grid__col {
    width: 100%;
    padding: 0 0 30px 0; }
    @media (min-width: 992px) {
      .grid__col {
        padding: 0 15px; } }
    .grid__col:first-child {
      padding: 0 0 30px 0; }
      @media (min-width: 992px) {
        .grid__col:first-child {
          padding: 0 30px 0 0; } }
    .grid__col:last-child {
      padding: 0 0 0 0; }
      @media (min-width: 992px) {
        .grid__col:last-child {
          padding: 0 0 0 30px; } }
    .grid__col--25 {
      width: 100%; }
      @media (min-width: 992px) {
        .grid__col--25 {
          width: 25%; } }
    .grid__col--75 {
      width: 100%; }
      @media (min-width: 992px) {
        .grid__col--75 {
          width: 75%; } }
  .grid__expand {
    display: flex;
    flex-direction: column; }
    @media (min-width: 992px) {
      .grid__expand {
        flex-direction: row; } }
    .grid__expand-left {
      width: calc(100% - 48px); }
      @media (min-width: 992px) {
        .grid__expand-left {
          width: calc(50% - 48px); } }
      .grid__expand-left-container {
        width: 100%;
        max-width: 100%;
        float: inherit;
        padding: 0 0 0 0; }
        @media (min-width: 992px) {
          .grid__expand-left-container {
            float: right;
            width: calc(100% - 60px);
            max-width: calc(1422px/2 - 60px);
            padding: 0 60px 0 0; } }
    .grid__expand-right {
      width: calc(100% - 48px);
      padding: 50px 24px 0 24px; }
      @media (min-width: 992px) {
        .grid__expand-right {
          width: 50%;
          padding: 0; } }

.color-element--malibu {
  color: #44D0FE; }

.color-element--white {
  color: white; }

.color-element--aquamarine {
  color: #7FFED1; }

.color-bg--clear {
  background-color: #F7FFFD; }

.color-bg--aquamarine {
  background-color: #7FFED1; }

.color-bg--stratos {
  background-color: #010033; }

.btn {
  background: #7ffed1;
  background: linear-gradient(143deg, #7ffed1 21%, #44d0fe 78%);
  height: 53px;
  display: flex;
  border-radius: 80px;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  line-height: 38px;
  width: max-content;
  min-width: 250px;
  cursor: pointer;
  font-family: 'Cocogoose Classic'; }
  @media (min-width: 992px) {
    .btn {
      min-width: 320px;
      height: 80px;
      font-size: 42px;
      line-height: 47px; } }
  .btn:hover {
    opacity: 0.8; }
  .btn > span {
    background-color: #010033;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .btn > span > strong {
      background: linear-gradient(to right, #7ffed1 0%, #44d0fe 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: normal;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
  .btn-size--l {
    font-size: 25px;
    height: 49px; }
    @media (min-width: 992px) {
      .btn-size--l {
        font-size: 37px;
        height: 69px; } }
  .btn-size--s {
    height: 35px;
    min-width: 150px;
    font-size: 17px;
    line-height: 19px; }
    @media (min-width: 992px) {
      .btn-size--s {
        height: 38px;
        min-width: 180px;
        font-size: 20px;
        line-height: 22px; } }
    .btn-size--s span {
      width: calc(100% - 4px);
      height: calc(100% - 4px); }
  .btn-line {
    border: 3px solid white;
    background: none;
    color: white; }
    .btn-line--black {
      border: 3px solid black;
      color: black; }
      .btn-line--black:hover {
        opacity: 0.6; }
  .btn-position--right-center-mobile {
    margin: 0 auto 0 auto; }
    @media (min-width: 992px) {
      .btn-position--right-center-mobile {
        margin: 0 0 0 auto; } }
  .btn-position--left-center-mobile {
    margin: 0 auto 0 auto; }
    @media (min-width: 992px) {
      .btn-position--left-center-mobile {
        margin: 0; } }

.image-text__blocks {
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .image-text__blocks {
      flex-direction: row; } }
  .image-text__blocks-left {
    width: 100%;
    padding: 0 0 20px 0; }
    @media (min-width: 768px) {
      .image-text__blocks-left {
        width: 43%;
        padding: 0 30px 0 0; } }
    .image-text__blocks-left-container {
      background: #7ffed1;
      background: linear-gradient(200deg, #7ffed1 21%, #44d0fe 78%);
      border-radius: 20px;
      padding: 20px 20px 50px 20px;
      color: white; }
      @media (min-width: 992px) {
        .image-text__blocks-left-container {
          padding: 40px 40px 120px 40px; } }
    .image-text__blocks-left-pretitle {
      font-size: 40px;
      line-height: 55px; }
    .image-text__blocks-left-title {
      font-size: 40px;
      line-height: 43px;
      font-family: 'Cocogoose Classic'; }
      @media (min-width: 992px) {
        .image-text__blocks-left-title {
          font-size: 60px;
          line-height: 83px; } }
    .image-text__blocks-left-description {
      font-size: 21px;
      line-height: 26px;
      color: black; }
      @media (min-width: 992px) {
        .image-text__blocks-left-description {
          font-size: 24px;
          line-height: 28px; } }
  .image-text__blocks-right {
    width: 100%;
    padding: 0 0 0 0; }
    @media (min-width: 768px) {
      .image-text__blocks-right {
        width: 57%;
        padding: 0 0 0 30px; } }
    .image-text__blocks-right img {
      border-radius: 20px;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%; }
  .image-text__blocks--reverse {
    flex-direction: column; }
    @media (min-width: 768px) {
      .image-text__blocks--reverse {
        flex-direction: row-reverse; } }
    .image-text__blocks--reverse .image-text__blocks-left {
      padding: 0 0 20px 0; }
      @media (min-width: 768px) {
        .image-text__blocks--reverse .image-text__blocks-left {
          padding: 0 70px 0 30px; } }
    .image-text__blocks--reverse .image-text__blocks-right {
      padding: 0 0 0 0; }
      @media (min-width: 768px) {
        .image-text__blocks--reverse .image-text__blocks-right {
          padding: 0 30px 0 0; } }

.image-text__basic {
  display: flex;
  flex-direction: column; }
  @media (min-width: 992px) {
    .image-text__basic {
      flex-direction: row; } }
  .image-text__basic-title {
    color: #44D0FE;
    font-family: 'Cocogoose Classic'; }
  .image-text__basic-left {
    width: 100%;
    padding: 0 0 40px 0; }
    @media (min-width: 992px) {
      .image-text__basic-left {
        width: calc(55% - 150px);
        padding: 0 150px 0 0; } }
  .image-text__basic-right {
    width: 100%;
    position: relative;
    text-align: center; }
    @media (min-width: 992px) {
      .image-text__basic-right {
        width: 45%;
        text-align: inherit; } }
    .image-text__basic-right img {
      width: auto;
      max-width: 250px;
      position: relative;
      top: 0;
      bottom: 0;
      margin: auto; }
      @media (min-width: 992px) {
        .image-text__basic-right img {
          position: absolute;
          max-width: 100%; } }

.form__text input {
  width: 100%;
  background-color: transparent;
  border: 0;
  border-bottom: 3px solid white;
  font-size: 20px;
  line-height: 24px;
  padding: 10px 0; }
  @media (min-width: 992px) {
    .form__text input {
      font-size: 24px;
      line-height: 28px; } }
  .form__text input::placeholder {
    color: white; }

.form__grid {
  display: flex; }
  .form__grid-left {
    width: 100%;
    max-width: 700px;
    padding-top: 60px; }
  .form__grid-box {
    background: #7ffed1;
    background: linear-gradient(237deg, #7ffed1 6%, #44d0fe 84%);
    padding: 30px 20px;
    border-radius: 20px;
    margin-top: 0; }
    @media (min-width: 992px) {
      .form__grid-box {
        margin-top: -220px;
        padding: 40px; } }
    .form__grid-box-title {
      font-family: 'Cocogoose Classic';
      padding-right: 0; }
      @media (min-width: 768px) {
        .form__grid-box-title {
          padding-right: 70px; } }
    .form__grid-box-description {
      padding-right: 0;
      font-size: 20px;
      line-height: 24px; }
      @media (min-width: 768px) {
        .form__grid-box-description {
          padding-right: 70px;
          font-size: 24px;
          line-height: 28px; } }

.form__message {
  padding: 10px;
  border-radius: 10px; }
  @media (min-width: 768px) {
    .form__message {
      padding: 20px; } }
  .form__message--error {
    background-color: #fff4f4e0;
    color: #ff1414; }

.form__error.form__text input {
  border-bottom: 3px solid #f61615; }
  .form__error.form__text input::placeholder {
    color: #f61615; }

.form__error.form__check label {
  color: #f61615; }

input:focus, select:focus, select, input.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none; }

.header {
  background-color: #010033;
  color: white;
  min-height: auto;
  padding-top: 10px; }
  @media (min-width: 1250px) {
    .header {
      min-height: 1100px;
      padding-top: 40px; } }
  .header--nobanner {
    min-height: auto; }
  .header__content {
    position: relative; }
    .header__content-head {
      height: 71px; }
      @media (min-width: 768px) {
        .header__content-head {
          height: 91px; } }
      .header__content-head-cols.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #050033;
        z-index: 21; }
        .header__content-head-cols.fixed .header__content-head-cols-container {
          width: calc(100% - 30px);
          max-width: 1494px;
          padding: 0 15px;
          height: 71px;
          transition: all 500ms; }
          .header__content-head-cols.fixed .header__content-head-cols-container .header__content-head-left img {
            transition: all 500ms;
            height: 45px;
            top: 2px; }
            @media (min-width: 768px) {
              .header__content-head-cols.fixed .header__content-head-cols-container .header__content-head-left img {
                height: 60px; } }
      .header__content-head-cols-container {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        height: 71px;
        transition: all 500ms;
        margin: auto; }
        @media (min-width: 768px) {
          .header__content-head-cols-container {
            height: 91px; } }
      .header__content-head-left {
        width: 140px; }
        @media (min-width: 1250px) {
          .header__content-head-left {
            width: 250px; } }
        .header__content-head-left img {
          position: relative;
          top: 0;
          height: 45px;
          transition: all 500ms; }
          @media (min-width: 768px) {
            .header__content-head-left img {
              height: 60px; } }
          @media (min-width: 1150px) {
            .header__content-head-left img {
              top: -10px; } }
          @media (min-width: 1250px) {
            .header__content-head-left img {
              height: 86px; } }
      .header__content-head-center {
        display: none; }
        @media (min-width: 1150px) {
          .header__content-head-center {
            display: flex;
            width: calc(100% - 370px);
            display: flex;
            justify-content: flex-end;
            padding: 0 10px 0 0; } }
        @media (min-width: 1250px) {
          .header__content-head-center {
            width: calc(100% - 620px); } }
        .header__content-head-center ul {
          display: flex;
          font-size: 18px;
          line-height: 20px;
          font-family: 'Cocogoose Classic'; }
          @media (min-width: 1250px) {
            .header__content-head-center ul {
              font-size: 20px; } }
          .header__content-head-center ul li {
            margin: 0 15px 0 0; }
            @media (min-width: 1400px) {
              .header__content-head-center ul li {
                margin: 0 20px 0 0; } }
            .header__content-head-center ul li:last-child {
              margin: 0; }
            .header__content-head-center ul li a:hover {
              opacity: 0.8; }
      .header__content-head-right {
        width: calc(100% - 190px);
        display: flex;
        justify-content: center; }
        @media (min-width: 1150px) {
          .header__content-head-right {
            width: 200px;
            justify-content: flex-end; } }
      .header__content-head-lang {
        width: 170px;
        font-size: 16px;
        text-align: right;
        display: none; }
        @media (min-width: 1150px) {
          .header__content-head-lang {
            align-items: center;
            display: block; } }
        .header__content-head-lang a {
          float: right;
          padding: 0 0 0 20px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: max-content;
          border: 0;
          background-color: transparent;
          color: inherit;
          font-size: inherit;
          position: relative;
          cursor: pointer; }
          .header__content-head-lang a:before {
            content: "";
            position: absolute;
            border-left: 1px solid #70708b;
            height: 30px;
            left: 0; }
        .header__content-head-lang i {
          opacity: 0.8;
          font-style: normal; }
        .header__content-head-lang img {
          height: 20px;
          margin: 0 10px 0 0; }
        .header__content-head-lang-mobile {
          border-top: 1px solid #ffffff4f;
          margin-top: 40px;
          padding-top: 40px;
          color: #9292a7;
          font-size: 15px;
          display: flex; }
          .header__content-head-lang-mobile img {
            height: 20px;
            margin: 0 10px 0 0; }
          .header__content-head-lang-mobile a {
            border: 0;
            background-color: transparent;
            font-size: inherit;
            color: inherit;
            display: flex;
            align-items: center; }
      .header__content-head-menu-mobile {
        display: block;
        width: 50px;
        display: flex;
        justify-content: flex-end; }
        @media (min-width: 1150px) {
          .header__content-head-menu-mobile {
            display: none; } }
  .header__info {
    margin-top: 40px;
    position: relative;
    z-index: 1; }
    @media (min-height: 992px) {
      .header__info {
        margin-top: 70px; } }
    .header__info-left {
      width: 100%; }
      @media (min-height: 992px) {
        .header__info-left {
          width: 60%; } }
      .header__info-left-title {
        font-family: 'Cocogoose Classic';
        color: #44D0FE;
        text-align: center; }
        @media (min-width: 992px) {
          .header__info-left-title {
            text-align: left; } }
      .header__info-left .app {
        display: flex;
        flex-direction: row;
        justify-content: center; }
        @media (min-width: 992px) {
          .header__info-left .app {
            justify-content: flex-start; } }
        .header__info-left .app img {
          margin: 0 10px 0 0;
          height: 50px; }
          @media (min-width: 768px) {
            .header__info-left .app img {
              height: inherit; } }
    .header__info-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: auto;
      padding-bottom: 50px; }
      @media (min-width: 992px) {
        .header__info-content {
          flex-direction: row;
          min-height: 600px;
          padding-bottom: 0; } }
      @media (min-width: 1250px) {
        .header__info-content {
          min-height: 800px; } }
    .header__info-img-mobile {
      display: block;
      text-align: center; }
      @media (min-width: 992px) {
        .header__info-img-mobile {
          display: none; } }
      .header__info-img-mobile img {
        margin-top: 50px;
        width: 100%;
        max-width: 400px; }
  .header__img {
    position: absolute;
    top: 0;
    width: 100%;
    display: none;
    align-items: flex-end;
    justify-content: flex-end; }
    @media (min-width: 992px) {
      .header__img {
        display: flex; } }
    .header__img img {
      max-width: 50vw;
      position: relative;
      bottom: -300px; }
      @media (min-width: 1250px) {
        .header__img img {
          max-width: 70vw;
          bottom: -290px; } }
      @media (min-width: 1550px) {
        .header__img img {
          max-width: 1030px;
          bottom: -220px; } }
  .header__mobile-menu {
    position: fixed;
    top: 0;
    width: calc(100% - 60px);
    background-color: #010033;
    height: 100%;
    z-index: 22;
    padding: 30px;
    right: -100%;
    transition: all 500ms; }
    .header__mobile-menu.open {
      right: 0%;
      transition: all 500ms; }
    .header__mobile-menu ul li {
      margin: 0 0 20px 0; }
    .header__mobile-menu-head {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .header__mobile-menu-content {
      padding: 60px 0 0 0; }

.accordion-header {
  padding: 20px 40px 20px 0;
  cursor: pointer;
  transition: all .3s;
  color: #44D0FE;
  font-size: 24px;
  line-height: 30px;
  border-bottom: 3px solid #44D0FE;
  position: relative;
  margin-bottom: 10px; }
  @media (min-width: 992px) {
    .accordion-header {
      font-size: 36px;
      line-height: 43px; } }
  .accordion-header:hover {
    position: relative;
    z-index: 5; }

.accordion-body {
  display: none; }
  .accordion-body__contents {
    padding: 20px 0; }
    .accordion-body__contents p {
      margin: 0 0 15px 0; }
    .accordion-body__contents ul {
      list-style: circle;
      padding-left: 30px; }
      .accordion-body__contents ul li {
        margin: 0 0 15px 0; }

.accordion__item > .accordion-header:after {
  content: url(../img/more-small.svg);
  position: absolute;
  transition: .3s all;
  transform: rotate(0deg);
  right: 0; }
  @media (min-width: 992px) {
    .accordion__item > .accordion-header:after {
      content: url(../img/more.svg); } }

.accordion__item.active > .accordion-header:after {
  content: url(../img/less-small.svg); }
  @media (min-width: 992px) {
    .accordion__item.active > .accordion-header:after {
      content: url(../img/less.svg); } }

.anchor {
  position: relative;
  top: -100px; }

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto; }
  .container--xxl {
    max-width: 1542px; }

.u-noscroll {
  overflow: hidden; }

.u-width-100 {
  width: 100%; }

.u-radius-20 {
  border-radius: 20px; }

.u-pos-relative {
  position: relative; }

.u-image-correction {
  margin-bottom: -8px; }

.u-text-decoration-none {
  text-decoration: none; }

.u-visible-xs {
  display: block !important; }
  @media (min-width: 992px) {
    .u-visible-xs {
      display: none !important; } }

.u-hidden-xs {
  display: none !important; }
  @media (min-width: 992px) {
    .u-hidden-xs {
      display: block !important; } }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-Regular.eot");
  src: url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Regular.woff2") format("woff2"), url("../fonts/Poppins-Regular.woff") format("woff"), url("../fonts/Poppins-Regular.ttf") format("truetype"), url("../fonts/Poppins-Regular.svg#Poppins-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Cocogoose Classic';
  src: url("../fonts/CocogooseClassic-Medium.eot");
  src: url("../fonts/CocogooseClassic-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/CocogooseClassic-Medium.woff2") format("woff2"), url("../fonts/CocogooseClassic-Medium.woff") format("woff"), url("../fonts/CocogooseClassic-Medium.ttf") format("truetype"), url("../fonts/CocogooseClassic-Medium.svg#CocogooseClassic-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Cocogoose Classic';
  src: url("../fonts/CocogooseClassic-Regular.eot");
  src: url("../fonts/CocogooseClassic-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/CocogooseClassic-Regular.woff2") format("woff2"), url("../fonts/CocogooseClassic-Regular.woff") format("woff"), url("../fonts/CocogooseClassic-Regular.ttf") format("truetype"), url("../fonts/CocogooseClassic-Regular.svg#CocogooseClassic-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

.u-px-contain {
  padding: 0 15px !important; }

.u-px-contain-xl {
  padding: 0 28px !important; }

.u-mb-module {
  margin-bottom: 50px !important; }

@media (min-width: 768px) {
  .u-px-contain {
    padding: 0 24px !important; }
  .u-px-contain-xl {
    padding: 0 0 !important; } }

@media (min-width: 992px) {
  .u-mb-module {
    margin-bottom: 420px !important; } }

.u-m-0 {
  margin: 0 !important; }

.u-mt-0 {
  margin-top: 0 !important; }

.u-ml-0 {
  margin-left: 0 !important; }

.u-mr-0 {
  margin-right: 0 !important; }

.u-mb-0 {
  margin-bottom: 0 !important; }

.u-m-xxxxs {
  margin: 2px !important; }

.u-m-xxxs {
  margin: 4px !important; }

.u-m-xxs {
  margin: 8px !important; }

.u-m-xs {
  margin: 12px !important; }

.u-m-s {
  margin: 16px !important; }

.u-m-m {
  margin: 20px !important; }

.u-m-l {
  margin: 24px !important; }

.u-m-xl {
  margin: calc(32px / 2) !important; }

.u-m-xxl {
  margin: calc(48px / 2) !important; }

.u-m-xxxl {
  margin: calc(64px / 2) !important; }

.u-m-xxxxl {
  margin: calc(96px / 2) !important; }

@media (min-width: 768px) {
  .u-m-xxxxs {
    margin: 2px !important; }
  .u-m-xxxs {
    margin: 4px !important; }
  .u-m-xxs {
    margin: 8px !important; }
  .u-m-xs {
    margin: 12px !important; }
  .u-m-s {
    margin: 16px !important; }
  .u-m-m {
    margin: 20px !important; }
  .u-m-l {
    margin: 24px !important; }
  .u-m-xl {
    margin: calc(32px / 1.5) !important; }
  .u-m-xxl {
    margin: calc(48px / 1.5) !important; }
  .u-m-xxxl {
    margin: calc(64px / 1.5) !important; }
  .u-m-xxxxl {
    margin: calc(96px / 1.5) !important; } }

@media (min-width: 992px) {
  .u-m-xxxxs {
    margin: 2px !important; }
  .u-m-xxxs {
    margin: 4px !important; }
  .u-m-xxs {
    margin: 8px !important; }
  .u-m-xs {
    margin: 12px !important; }
  .u-m-s {
    margin: 16px !important; }
  .u-m-m {
    margin: 20px !important; }
  .u-m-l {
    margin: 24px !important; }
  .u-m-xl {
    margin: 32px !important; }
  .u-m-xxl {
    margin: 48px !important; }
  .u-m-xxxl {
    margin: 64px !important; }
  .u-m-xxxxl {
    margin: 96px !important; } }

.u-mt-xxxxs {
  margin-top: 2px !important; }

.u-mt-xxxs {
  margin-top: 4px !important; }

.u-mt-xxs {
  margin-top: 8px !important; }

.u-mt-xs {
  margin-top: 12px !important; }

.u-mt-s {
  margin-top: 16px !important; }

.u-mt-m {
  margin-top: 20px !important; }

.u-mt-l {
  margin-top: calc(24px / 2) !important; }

.u-mt-xl {
  margin-top: calc(32px / 2) !important; }

.u-mt-xxl {
  margin-top: calc(48px / 2) !important; }

.u-mt-xxxl {
  margin-top: calc(64px / 2) !important; }

.u-mt-xxxxl {
  margin-top: calc(96px / 2) !important; }

@media (min-width: 768px) {
  .u-mt-xxxxs {
    margin-top: 2px !important; }
  .u-mt-xxxs {
    margin-top: 4px !important; }
  .u-mt-xxs {
    margin-top: 8px !important; }
  .u-mt-xs {
    margin-top: 12px !important; }
  .u-mt-s {
    margin-top: 16px !important; }
  .u-mt-m {
    margin-top: 20px !important; }
  .u-mt-l {
    margin-top: calc(24px / 1.5) !important; }
  .u-mt-xl {
    margin-top: calc(32px / 1.5) !important; }
  .u-mt-xxl {
    margin-top: calc(48px / 1.5) !important; }
  .u-mt-xxxl {
    margin-top: calc(64px / 1.5) !important; }
  .u-mt-xxxxl {
    margin-top: calc(96px / 1.5) !important; } }

@media (min-width: 992px) {
  .u-mt-xxxxs {
    margin-top: 2px !important; }
  .u-mt-xxxs {
    margin-top: 4px !important; }
  .u-mt-xxs {
    margin-top: 8px !important; }
  .u-mt-xs {
    margin-top: 12px !important; }
  .u-mt-m {
    margin-top: 20px !important; }
  .u-mt-l {
    margin-top: 24px !important; }
  .u-mt-xl {
    margin-top: 32px !important; }
  .u-mt-xxl {
    margin-top: 48px !important; }
  .u-mt-xxxl {
    margin-top: 64px !important; }
  .u-mt-xxxxl {
    margin-top: 96px !important; } }

.u-mb-xxxxs {
  margin-bottom: 2px !important; }

.u-mb-xxxs {
  margin-bottom: 4px !important; }

.u-mb-xxs {
  margin-bottom: 8px !important; }

.u-mb-xs {
  margin-bottom: 12px !important; }

.u-mb-s {
  margin-bottom: 16px !important; }

.u-mb-m {
  margin-bottom: 20px !important; }

.u-mb-l {
  margin-bottom: 24px !important; }

.u-mb-xl {
  margin-bottom: calc(32px / 2) !important; }

.u-mb-xxl {
  margin-bottom: calc(48px / 2) !important; }

.u-mb-xxxl {
  margin-bottom: calc(64px / 2) !important; }

.u-mb-xxxxl {
  margin-bottom: calc(96px / 2) !important; }

@media (min-width: 768px) {
  .u-mb-xxxxs {
    margin-bottom: 2px !important; }
  .u-mb-xxxs {
    margin-bottom: 4px !important; }
  .u-mb-xxs {
    margin-bottom: 8px !important; }
  .u-mb-xs {
    margin-bottom: 12px !important; }
  .u-mb-s {
    margin-bottom: 16px !important; }
  .u-mb-m {
    margin-bottom: 20px !important; }
  .u-mb-l {
    margin-bottom: 24px !important; }
  .u-mb-xl {
    margin-bottom: calc(32px / 1.5) !important; }
  .u-mb-xxl {
    margin-bottom: calc(48px / 1.5) !important; }
  .u-mb-xxxl {
    margin-bottom: calc(64px / 1.5) !important; }
  .u-mb-xxxxl {
    margin-bottom: calc(96px / 1.5) !important; } }

@media (min-width: 992px) {
  .u-mb-xxxxs {
    margin-bottom: 2px !important; }
  .u-mb-xxxs {
    margin-bottom: 4px !important; }
  .u-mb-xxs {
    margin-bottom: 8px !important; }
  .u-mb-xs {
    margin-bottom: 12px !important; }
  .u-mb-s {
    margin-bottom: 16px !important; }
  .u-mb-m {
    margin-bottom: 20px !important; }
  .u-mb-l {
    margin-bottom: 24px !important; }
  .u-mb-xl {
    margin-bottom: 32px !important; }
  .u-mb-xxl {
    margin-bottom: 48px !important; }
  .u-mb-xxxl {
    margin-bottom: 64px !important; }
  .u-mb-xxxxl {
    margin-bottom: 96px !important; } }

.u-ml-xxxxs {
  margin-left: 2px !important; }

.u-ml-xxxs {
  margin-left: 4px !important; }

.u-ml-xxs {
  margin-left: 8px !important; }

.u-ml-xs {
  margin-left: 12px !important; }

.u-ml-s {
  margin-left: 16px !important; }

.u-ml-m {
  margin-left: 20px !important; }

.u-ml-l {
  margin-left: 24px !important; }

.u-ml-xl {
  margin-left: 32px !important; }

.u-ml-xxl {
  margin-left: 48px !important; }

.u-ml-xxxl {
  margin-left: 64px !important; }

.u-ml-xxxxl {
  margin-left: 96px !important; }

.u-mr-xxxxs {
  margin-right: 2px !important; }

.u-mr-xxxs {
  margin-right: 4px !important; }

.u-mr-xxs {
  margin-right: 8px !important; }

.u-mr-xs {
  margin-right: 12px !important; }

.u-mr-s {
  margin-right: 16px !important; }

.u-mr-m {
  margin-right: 20px !important; }

.u-mr-l {
  margin-right: 24px !important; }

.u-mr-xl {
  margin-right: 32px !important; }

.u-mr-xxl {
  margin-right: 48px !important; }

.u-mr-xxxl {
  margin-right: 64px !important; }

.u-mr-xxxxl {
  margin-right: 96px !important; }

.u-mx-xxxxs {
  margin: 0 2px 0 2px !important; }

.u-mx-xxxs {
  margin: 0 4px 0 4px !important; }

.u-mx-xxs {
  margin: 0 8px 0 8px !important; }

.u-mx-xs {
  margin: 0 12px 0 12px !important; }

.u-mx-s {
  margin: 0 16px 0 16px !important; }

.u-mx-m {
  margin: 0 20px 0 20px !important; }

.u-mx-l {
  margin: 0 24px 0 24px !important; }

.u-mx-xl {
  margin: 0 32px 0 32px !important; }

.u-mx-xxl {
  margin: 0 48px 0 48px !important; }

.u-mx-xxxl {
  margin: 0 64px 0 64px !important; }

.u-mx-xxxxl {
  margin: 0 96px 0 96px !important; }

.u-my-xxxxs {
  margin: 2px auto 2px auto !important; }

.u-my-xxxs {
  margin: 4px auto 4px auto !important; }

.u-my-xxs {
  margin: 8px auto 8px auto !important; }

.u-my-xs {
  margin: 12px auto 12px auto !important; }

.u-my-s {
  margin: 16px auto 16px auto !important; }

.u-my-m {
  margin: 20px auto 20px auto !important; }

.u-my-l {
  margin: 24px auto 24px auto !important; }

.u-my-xl {
  margin: calc(32px / 2) auto calc(32px / 2) auto !important; }

.u-my-xxl {
  margin: calc(48px / 2) auto calc(48px / 2) auto !important; }

.u-my-xxxl {
  margin: calc(64px / 2) auto calc(64px / 2) auto !important; }

.u-my-xxxxl {
  margin: calc(96px / 2) auto calc(96px / 2) auto !important; }

@media (min-width: 768px) {
  .u-my-xxxxs {
    margin: 2px auto 2px auto !important; }
  .u-my-xxxs {
    margin: 4px auto 4px auto !important; }
  .u-my-xxs {
    margin: 8px auto 8px auto !important; }
  .u-my-xs {
    margin: 12px auto 12px auto !important; }
  .u-my-s {
    margin: 16px auto 16px auto !important; }
  .u-my-m {
    margin: 20px auto 20px auto !important; }
  .u-my-l {
    margin: 24px auto 24px auto !important; }
  .u-my-xl {
    margin: calc(32px / 1.5) auto calc(32px / 1.5) auto !important; }
  .u-my-xxl {
    margin: calc(48px / 1.5) auto calc(48px / 1.5) auto !important; }
  .u-my-xxxl {
    margin: calc(64px / 1.5) auto calc(64px / 1.5) auto !important; }
  .u-my-xxxxl {
    margin: calc(96px / 1.5) auto calc(96px / 1.5) auto !important; } }

@media (min-width: 992px) {
  .u-my-xxxxs {
    margin: 2px auto 2px auto !important; }
  .u-my-xxxs {
    margin: 4px auto 4px auto !important; }
  .u-my-xxs {
    margin: 8px auto 8px auto !important; }
  .u-my-xs {
    margin: 12px auto 12px auto !important; }
  .u-my-s {
    margin: 16px auto 16px auto !important; }
  .u-my-m {
    margin: 20px auto 20px auto !important; }
  .u-my-l {
    margin: 24px auto 24px auto !important; }
  .u-my-xl {
    margin: 32px auto 32px auto !important; }
  .u-my-xxl {
    margin: 48px auto 48px auto !important; }
  .u-my-xxxl {
    margin: 64px auto 64px auto !important; }
  .u-my-xxxxl {
    margin: 96px auto 96px auto !important; } }

.u-mt-xxxxs-responsive {
  margin-top: 2px !important; }

.u-mt-xxxs-responsive {
  margin-top: 4px !important; }

.u-mt-xxs-responsive {
  margin-top: 8px !important; }

.u-mt-xs-responsive {
  margin-top: 12px !important; }

.u-mt-s-responsive {
  margin-top: 16px !important; }

.u-mt-m-responsive {
  margin-top: 20px !important; }

.u-mt-l-responsive {
  margin-top: 24px !important; }

.u-mt-xl-responsive {
  margin-top: calc(32px / 2) !important; }

.u-mt-xxl-responsive {
  margin-top: calc(48px / 2) !important; }

.u-mt-xxxl-responsive {
  margin-top: calc(64px / 2) !important; }

.u-mt-xxxxl-responsive {
  margin-top: calc(96px / 2) !important; }

@media (min-width: 992px) {
  .u-mt-xxxxs-responsive {
    margin-top: 0 !important; }
  .u-mt-xxxs-responsive {
    margin-top: 0 !important; }
  .u-mt-xxs-responsive {
    margin-top: 0 !important; }
  .u-mt-xs-responsive {
    margin-top: 0 !important; }
  .u-mt-s-responsive {
    margin-top: 0 !important; }
  .u-mt-m-responsive {
    margin-top: 0 !important; }
  .u-mt-l-responsive {
    margin-top: 0 !important; }
  .u-mt-xl-responsive {
    margin-top: 0 !important; }
  .u-mt-xxl-responsive {
    margin-top: 0 !important; }
  .u-mt-xxxl-responsive {
    margin-top: 0 !important; }
  .u-mt-xxxxl-responsive {
    margin-top: 0 !important; } }

.u-mb-xxxxs-responsive {
  margin-bottom: 2px !important; }

.u-mb-xxxs-responsive {
  margin-bottom: 4px !important; }

.u-mb-xxs-responsive {
  margin-bottom: 8px !important; }

.u-mb-xs-responsive {
  margin-bottom: 12px !important; }

.u-mb-s-responsive {
  margin-bottom: 16px !important; }

.u-mb-m-responsive {
  margin-bottom: 20px !important; }

.u-mb-l-responsive {
  margin-bottom: 24px !important; }

.u-mb-xl-responsive {
  margin-bottom: calc(32px / 2) !important; }

.u-mb-xxl-responsive {
  margin-bottom: calc(48px / 2) !important; }

.u-mb-xxxl-responsive {
  margin-bottom: calc(64px / 2) !important; }

.u-mb-xxxxl-responsive {
  margin-bottom: calc(96px / 2) !important; }

@media (min-width: 992px) {
  .u-mb-xxxxs-responsive {
    margin-bottom: 0 !important; }
  .u-mb-xxxs-responsive {
    margin-bottom: 0 !important; }
  .u-mb-xxs-responsive {
    margin-bottom: 0 !important; }
  .u-mb-xs-responsive {
    margin-bottom: 0 !important; }
  .u-mb-s-responsive {
    margin-bottom: 0 !important; }
  .u-mb-m-responsive {
    margin-bottom: 0 !important; }
  .u-mb-l-responsive {
    margin-bottom: 0 !important; }
  .u-mb-xl-responsive {
    margin-bottom: 0 !important; }
  .u-mb-xxl-responsive {
    margin-bottom: 0 !important; }
  .u-mb-xxxl-responsive {
    margin-bottom: 0 !important; }
  .u-mb-xxxxl-responsive {
    margin-bottom: 0 !important; } }

.u-mt-xxxxs-desktop {
  margin-top: 0 !important; }

.u-mt-xxxs-desktop {
  margin-top: 0 !important; }

.u-mt-xxs-desktop {
  margin-top: 0 !important; }

.u-mt-xs-desktop {
  margin-top: 0 !important; }

.u-mt-s-desktop {
  margin-top: 0 !important; }

.u-mt-m-desktop {
  margin-top: 0 !important; }

.u-mt-l-desktop {
  margin-top: 0 !important; }

.u-mt-xl-desktop {
  margin-top: 0 !important; }

.u-mt-xxl-desktop {
  margin-top: 0 !important; }

.u-mt-xxxl-desktop {
  margin-top: 0 !important; }

.u-mt-xxxxl-desktop {
  margin-top: 0 !important; }

@media (min-width: 992px) {
  .u-mt-xxxxs-desktop {
    margin-top: 2px !important; }
  .u-mt-xxxs-desktop {
    margin-top: 4px !important; }
  .u-mt-xxs-desktop {
    margin-top: 8px !important; }
  .u-mt-xs-desktop {
    margin-top: 12px !important; }
  .u-mt-s-desktop {
    margin-top: 16px !important; }
  .u-mt-m-desktop {
    margin-top: 20px !important; }
  .u-mt-l-desktop {
    margin-top: 24px !important; }
  .u-mt-xl-desktop {
    margin-top: 32px !important; }
  .u-mt-xxl-desktop {
    margin-top: 48px !important; }
  .u-mt-xxxl-desktop {
    margin-top: 64px !important; }
  .u-mt-xxxxl-desktop {
    margin-top: 96px !important; } }

.u-mb-xxxxs-desktop {
  margin-bottom: 0 !important; }

.u-mb-xxxs-desktop {
  margin-bottom: 0 !important; }

.u-mb-xxs-desktop {
  margin-bottom: 0 !important; }

.u-mb-xs-desktop {
  margin-bottom: 0 !important; }

.u-mb-s-desktop {
  margin-bottom: 0 !important; }

.u-mb-m-desktop {
  margin-bottom: 0 !important; }

.u-mb-l-desktop {
  margin-bottom: 0 !important; }

.u-mb-xl-desktop {
  margin-bottom: 0 !important; }

.u-mb-xxl-desktop {
  margin-bottom: 0 !important; }

.u-mb-xxxl-desktop {
  margin-bottom: 0 !important; }

.u-mb-xxxxl-desktop {
  margin-bottom: 0 !important; }

@media (min-width: 992px) {
  .u-mb-xxxxs-desktop {
    margin-bottom: 2px !important; }
  .u-mb-xxxs-desktop {
    margin-bottom: 4px !important; }
  .u-mb-xxs-desktop {
    margin-bottom: 8px !important; }
  .u-mb-xs-desktop {
    margin-bottom: 12px !important; }
  .u-mb-s-desktop {
    margin-bottom: 16px !important; }
  .u-mb-m-desktop {
    margin-bottom: 20px !important; }
  .u-mb-l-desktop {
    margin-bottom: 24px !important; }
  .u-mb-xl-desktop {
    margin-bottom: 32px !important; }
  .u-mb-xxl-desktop {
    margin-bottom: 48px !important; }
  .u-mb-xxxl-desktop {
    margin-bottom: 64px !important; }
  .u-mb-xxxxl-desktop {
    margin-bottom: 96px !important; } }

.u-ml-xxxxs-desktop {
  margin-left: 0 !important; }

.u-ml-xxxs-desktop {
  margin-left: 0 !important; }

.u-ml-xxs-desktop {
  margin-left: 0 !important; }

.u-ml-xs-desktop {
  margin-left: 0 !important; }

.u-ml-s-desktop {
  margin-left: 0 !important; }

.u-ml-m-desktop {
  margin-left: 0 !important; }

.u-ml-l-desktop {
  margin-left: 0 !important; }

.u-ml-xl-desktop {
  margin-left: 0 !important; }

.u-ml-xxl-desktop {
  margin-left: 0 !important; }

.u-ml-xxxl-desktop {
  margin-left: 0 !important; }

.u-ml-xxxxl-desktop {
  margin-left: 0 !important; }

@media (min-width: 992px) {
  .u-ml-xxxxs-desktop {
    margin-left: 2px !important; }
  .u-ml-xxxs-desktop {
    margin-left: 4px !important; }
  .u-ml-xxs-desktop {
    margin-left: 8px !important; }
  .u-ml-xs-desktop {
    margin-left: 12px !important; }
  .u-ml-s-desktop {
    margin-left: 16px !important; }
  .u-ml-m-desktop {
    margin-left: 20px !important; }
  .u-ml-l-desktop {
    margin-left: 24px !important; }
  .u-ml-xl-desktop {
    margin-left: 32px !important; }
  .u-ml-xxl-desktop {
    margin-left: 48px !important; }
  .u-ml-xxxl-desktop {
    margin-left: 64px !important; }
  .u-ml-xxxxl-desktop {
    margin-left: 96px !important; } }

.u-mr-xxxxs-desktop {
  margin-right: 0 !important; }

.u-mr-xxxs-desktop {
  margin-right: 0 !important; }

.u-mr-xxs-desktop {
  margin-right: 0 !important; }

.u-mr-xs-desktop {
  margin-right: 0 !important; }

.u-mr-s-desktop {
  margin-right: 0 !important; }

.u-mr-m-desktop {
  margin-right: 0 !important; }

.u-mr-l-desktop {
  margin-right: 0 !important; }

.u-mr-xl-desktop {
  margin-right: 0 !important; }

.u-mr-xxl-desktop {
  margin-right: 0 !important; }

.u-mr-xxxl-desktop {
  margin-right: 0 !important; }

.u-mr-xxxxl-desktop {
  margin-right: 0 !important; }

@media (min-width: 992px) {
  .u-mr-xxxxs-desktop {
    margin-right: 2px !important; }
  .u-mr-xxxs-desktop {
    margin-right: 4px !important; }
  .u-mr-xxs-desktop {
    margin-right: 8px !important; }
  .u-mr-xs-desktop {
    margin-right: 12px !important; }
  .u-mr-s-desktop {
    margin-right: 16px !important; }
  .u-mr-m-desktop {
    margin-right: 20px !important; }
  .u-mr-l-desktop {
    margin-right: 24px !important; }
  .u-mr-xl-desktop {
    margin-right: 32px !important; }
  .u-mr-xxl-desktop {
    margin-right: 48px !important; }
  .u-mr-xxxl-desktop {
    margin-right: 64px !important; }
  .u-mr-xxxxl-desktop {
    margin-right: 96px !important; } }

.u-p-0 {
  padding: 0 !important; }

.u-pt-0 {
  padding-top: 0 !important; }

.u-pl-0 {
  padding-left: 0 !important; }

.u-pr-0 {
  padding-right: 0 !important; }

.u-pb-0 {
  padding-bottom: 0 !important; }

.u-p-xxxxs {
  padding: 2px !important; }

.u-p-xxxs {
  padding: 4px !important; }

.u-p-xxs {
  padding: 8px !important; }

.u-p-xs {
  padding: 12px !important; }

.u-p-s {
  padding: 16px !important; }

.u-p-m {
  padding: 20px !important; }

.u-p-l {
  padding: calc(24px / 2) !important; }

.u-p-xl {
  padding: calc(32px / 2) !important; }

.u-p-xxl {
  padding: calc(48px / 2) !important; }

.u-p-xxxl {
  padding: calc(64px / 2) !important; }

.u-p-xxxxl {
  padding: calc(96px / 2) !important; }

@media (min-width: 768px) {
  .u-p-xxxxs {
    padding: 2px !important; }
  .u-p-xxxs {
    padding: 4px !important; }
  .u-p-xxs {
    padding: 8px !important; }
  .u-p-xs {
    padding: 12px !important; }
  .u-p-s {
    padding: 16px !important; }
  .u-p-m {
    padding: 20px !important; }
  .u-p-l {
    padding: calc(24px / 1.5) !important; }
  .u-p-xl {
    padding: calc(32px / 1.5) !important; }
  .u-p-xxl {
    padding: calc(48px / 1.5) !important; }
  .u-p-xxxl {
    padding: calc(64px / 1.5) !important; }
  .u-p-xxxxl {
    padding: calc(96px / 1.5) !important; } }

@media (min-width: 992px) {
  .u-p-xxxxs {
    padding: 2px !important; }
  .u-p-xxxs {
    padding: 4px !important; }
  .u-p-xxs {
    padding: 8px !important; }
  .u-p-xs {
    padding: 12px !important; }
  .u-p-s {
    padding: 16px !important; }
  .u-p-m {
    padding: 20px !important; }
  .u-p-l {
    padding: 24px !important; }
  .u-p-xl {
    padding: 32px !important; }
  .u-p-xxl {
    padding: 48px !important; }
  .u-p-xxxl {
    padding: 64px !important; }
  .u-p-xxxxl {
    padding: 96px !important; } }

.u-pt-xxxxs {
  padding-top: 2px !important; }

.u-pt-xxxs {
  padding-top: 4px !important; }

.u-pt-xxs {
  padding-top: 8px !important; }

.u-pt-xs {
  padding-top: 12px !important; }

.u-pt-s {
  padding-top: 16px !important; }

.u-pt-m {
  padding-top: 20px !important; }

.u-pt-l {
  padding-top: calc(24px / 2) !important; }

.u-pt-xl {
  padding-top: calc(32px / 2) !important; }

.u-pt-xxl {
  padding-top: calc(48px / 2) !important; }

.u-pt-xxxl {
  padding-top: calc(64px / 2) !important; }

.u-pt-xxxxl {
  padding-top: calc(96px / 2) !important; }

@media (min-width: 768px) {
  .u-pt-xxxxs {
    padding-top: 2px !important; }
  .u-pt-xxxs {
    padding-top: 4px !important; }
  .u-pt-xxs {
    padding-top: 8px !important; }
  .u-pt-xs {
    padding-top: 12px !important; }
  .u-pt-s {
    padding-top: 16px !important; }
  .u-pt-m {
    padding-top: 20px !important; }
  .u-pt-l {
    padding-top: calc(24px / 1.5) !important; }
  .u-pt-xl {
    padding-top: calc(32px / 1.5) !important; }
  .u-pt-xxl {
    padding-top: calc(48px / 1.5) !important; }
  .u-pt-xxxl {
    padding-top: calc(64px / 1.5) !important; }
  .u-pt-xxxxl {
    padding-top: calc(96px / 1.5) !important; } }

@media (min-width: 992px) {
  .u-pt-xxxxs {
    padding-top: 2px !important; }
  .u-pt-xxxs {
    padding-top: 4px !important; }
  .u-pt-xxs {
    padding-top: 8px !important; }
  .u-pt-xs {
    padding-top: 12px !important; }
  .u-pt-s {
    padding-top: 16px !important; }
  .u-pt-m {
    padding-top: 20px !important; }
  .u-pt-l {
    padding-top: 24px !important; }
  .u-pt-xl {
    padding-top: 32px !important; }
  .u-pt-xxl {
    padding-top: 48px !important; }
  .u-pt-xxxl {
    padding-top: 64px !important; }
  .u-pt-xxxxl {
    padding-top: 96px !important; } }

.u-pb-xxxxs {
  padding-bottom: 2px !important; }

.u-pb-xxxs {
  padding-bottom: 4px !important; }

.u-pb-xxs {
  padding-bottom: 8px !important; }

.u-pb-xs {
  padding-bottom: 12px !important; }

.u-pb-s {
  padding-bottom: 16px !important; }

.u-pb-m {
  padding-bottom: 20px !important; }

.u-pb-l {
  padding-bottom: calc(24px / 2) !important; }

.u-pb-xl {
  padding-bottom: calc(32px / 2) !important; }

.u-pb-xxl {
  padding-bottom: calc(48px / 2) !important; }

.u-pb-xxxl {
  padding-bottom: calc(64px / 2) !important; }

.u-pb-xxxxl {
  padding-bottom: calc(96px / 2) !important; }

@media (min-width: 768px) {
  .u-pb-xxxxs {
    padding-bottom: 2px !important; }
  .u-pb-xxxs {
    padding-bottom: 4px !important; }
  .u-pb-xxs {
    padding-bottom: 8px !important; }
  .u-pb-xs {
    padding-bottom: 12px !important; }
  .u-pb-s {
    padding-bottom: 16px !important; }
  .u-pb-m {
    padding-bottom: 20px !important; }
  .u-pb-l {
    padding-bottom: calc(24px / 1.5) !important; }
  .u-pb-xl {
    padding-bottom: calc(32px / 1.5) !important; }
  .u-pb-xxl {
    padding-bottom: calc(48px / 1.5) !important; }
  .u-pb-xxxl {
    padding-bottom: calc(64px / 1.5) !important; }
  .u-pb-xxxxl {
    padding-bottom: calc(96px / 1.5) !important; } }

@media (min-width: 992px) {
  .u-pb-xxxxs {
    padding-bottom: 2px !important; }
  .u-pb-xxxs {
    padding-bottom: 4px !important; }
  .u-pb-xxs {
    padding-bottom: 8px !important; }
  .u-pb-xs {
    padding-bottom: 12px !important; }
  .u-pb-s {
    padding-bottom: 16px !important; }
  .u-pb-m {
    padding-bottom: 20px !important; }
  .u-pb-l {
    padding-bottom: 24px !important; }
  .u-pb-xl {
    padding-bottom: 32px !important; }
  .u-pb-xxl {
    padding-bottom: 48px !important; }
  .u-pb-xxxl {
    padding-bottom: 64px !important; }
  .u-pb-xxxxl {
    padding-bottom: 96px !important; } }

.u-pl-xxxxs {
  padding-left: 2px !important; }

.u-pl-xxxs {
  padding-left: 4px !important; }

.u-pl-xxs {
  padding-left: 8px !important; }

.u-pl-xs {
  padding-left: 12px !important; }

.u-pl-s {
  padding-left: 16px !important; }

.u-pl-m {
  padding-left: 20px !important; }

.u-pl-l {
  padding-left: 24px !important; }

.u-pl-xl {
  padding-left: calc(32px / 2) !important; }

.u-pl-xxl {
  padding-left: calc(48px / 2) !important; }

.u-pl-xxxl {
  padding-left: calc(64px / 2) !important; }

.u-pl-xxxxl {
  padding-left: calc(96px / 2) !important; }

@media (min-width: 992px) {
  .u-pl-xxxxs {
    padding-left: 2px !important; }
  .u-pl-xxxs {
    padding-left: 4px !important; }
  .u-pl-xxs {
    padding-left: 8px !important; }
  .u-pl-xs {
    padding-left: 12px !important; }
  .u-pl-s {
    padding-left: 16px !important; }
  .u-pl-m {
    padding-left: 20px !important; }
  .u-pl-l {
    padding-left: 24px !important; }
  .u-pl-xl {
    padding-left: 32px !important; }
  .u-pl-xxl {
    padding-left: 48px !important; }
  .u-pl-xxxl {
    padding-left: 64px !important; }
  .u-pl-xxxxl {
    padding-left: 96px !important; } }

.u-pr-xxxxs {
  padding-right: 2px !important; }

.u-pr-xxxs {
  padding-right: 4px !important; }

.u-pr-xxs {
  padding-right: 8px !important; }

.u-pr-xs {
  padding-right: 12px !important; }

.u-pr-s {
  padding-right: 16px !important; }

.u-pr-m {
  padding-right: 20px !important; }

.u-pr-l {
  padding-right: 24px !important; }

.u-pr-xl {
  padding-right: 32px !important; }

.u-pr-xxl {
  padding-right: 48px !important; }

.u-pr-xxxl {
  padding-right: 64px !important; }

.u-pr-xxxxl {
  padding-right: 96px !important; }

.u-px-xxxxs {
  padding: 0 2px 0 2px !important; }

.u-px-xxxs {
  padding: 0 4px 0 4px !important; }

.u-px-xxs {
  padding: 0 8px 0 8px !important; }

.u-px-xs {
  padding: 0 12px 0 12px !important; }

.u-px-s {
  padding: 0 16px 0 16px !important; }

.u-px-m {
  padding: 0 20px 0 20px !important; }

.u-px-l {
  padding: 0 24px 0 24px !important; }

.u-px-xl {
  padding: 0 32px 0 32px !important; }

.u-px-xxl {
  padding: 0 48px 0 48px !important; }

.u-px-xxxl {
  padding: 0 64px 0 64px !important; }

.u-px-xxxxl {
  padding: 0 96px 0 96px !important; }

.u-px-xxxxs {
  padding: 0 2px 0 2px !important; }

.u-px-xxxs {
  padding: 0 4px 0 4px !important; }

.u-px-xxs {
  padding: 0 8px 0 8px !important; }

.u-px-xs {
  padding: 0 12px 0 12px !important; }

.u-px-s {
  padding: 0 16px 0 16px !important; }

.u-px-m {
  padding: 0 20px 0 20px !important; }

.u-px-l {
  padding: 0 24px 0 24px !important; }

.u-px-xl {
  padding: 0 calc(32px / 2) 0 calc(32px / 2) !important; }

.u-px-xxl {
  padding: 0 calc(48px / 2) 0 calc(48px / 2) !important; }

.u-px-xxxl {
  padding: 0 calc(64px / 2) 0 calc(64px / 2) !important; }

.u-px-xxxxl {
  padding: 0 calc(96px / 2) 0 calc(96px / 2) !important; }

@media (min-width: 768px) {
  .u-px-xxxxs {
    padding: 0 2px 0 2px !important; }
  .u-px-xxxs {
    padding: 0 4px 0 4px !important; }
  .u-px-xxs {
    padding: 0 8px 0 8px !important; }
  .u-px-xs {
    padding: 0 12px 0 12px !important; }
  .u-px-s {
    padding: 0 16px 0 16px !important; }
  .u-px-m {
    padding: 0 20px 0 20px !important; }
  .u-px-l {
    padding: 0 24px 0 24px !important; }
  .u-px-xl {
    padding: 0 calc(32px / 1.5) 0 calc(32px / 1.5) !important; }
  .u-px-xxl {
    padding: 0 calc(48px / 1.5) 0 calc(48px / 1.5) !important; }
  .u-px-xxxl {
    padding: 0 calc(64px / 1.5) 0 calc(64px / 1.5) !important; }
  .u-px-xxxxl {
    padding: 0 calc(96px / 1.5) 0 calc(96px / 1.5) !important; } }

@media (min-width: 992px) {
  .u-px-xxxxs {
    padding: 0 2px 0 2px !important; }
  .u-px-xxxs {
    padding: 0 4px 0 4px !important; }
  .u-px-xxs {
    padding: 0 8px 0 8px !important; }
  .u-px-xs {
    padding: 0 12px 0 12px !important; }
  .u-px-s {
    padding: 0 16px 0 16px !important; }
  .u-px-m {
    padding: 0 20px 0 20px !important; }
  .u-px-l {
    padding: 0 24px 0 24px !important; }
  .u-px-xl {
    padding: 0 32px 0 32px !important; }
  .u-px-xxl {
    padding: 0 48px 0 48px !important; }
  .u-px-xxxl {
    padding: 0 64px 0 64px !important; }
  .u-px-xxxxl {
    padding: 0 96px 0 96px !important; } }

.u-py-xxxxs {
  padding: 2px 0 2px 0 !important; }

.u-py-xxxs {
  padding: 4px 0 4px 0 !important; }

.u-py-xxs {
  padding: 8px 0 8px 0 !important; }

.u-py-xs {
  padding: 12px 0 12px 0 !important; }

.u-py-s {
  padding: 16px 0 16px 0 !important; }

.u-py-m {
  padding: 20px 0 20px 0 !important; }

.u-py-l {
  padding: 24px 0 24px 0 !important; }

.u-py-xl {
  padding: calc(32px / 2) 0 calc(32px / 2) 0 !important; }

.u-py-xxl {
  padding: calc(48px / 2) 0 calc(48px / 2) 0 !important; }

.u-py-xxxl {
  padding: calc(64px / 2) 0 calc(64px / 2) 0 !important; }

.u-py-xxxxl {
  padding: calc(96px / 2) 0 calc(96px / 2) 0 !important; }

@media (min-width: 768px) {
  .u-py-xxxxs {
    padding: 2px 0 2px 0 !important; }
  .u-py-xxxs {
    padding: 4px 0 4px 0 !important; }
  .u-py-xxs {
    padding: 8px 0 8px 0 !important; }
  .u-py-xs {
    padding: 12px 0 12px 0 !important; }
  .u-py-s {
    padding: 16px 0 16px 0 !important; }
  .u-py-m {
    padding: 20px 0 20px 0 !important; }
  .u-py-l {
    padding: 24px 0 24px 0 !important; }
  .u-py-xl {
    padding: calc(32px / 1.5) 0 calc(32px / 1.5) 0 !important; }
  .u-py-xxl {
    padding: calc(48px / 1.5) 0 calc(48px / 1.5) 0 !important; }
  .u-py-xxxl {
    padding: calc(64px / 1.5) 0 calc(64px / 1.5) 0 !important; }
  .u-py-xxxxl {
    padding: calc(96px / 1.5) 0 calc(96px / 1.5) 0 !important; } }

@media (min-width: 992px) {
  .u-py-xxxxs {
    padding: 2px 0 2px 0 !important; }
  .u-py-xxxs {
    padding: 4px 0 4px 0 !important; }
  .u-py-xxs {
    padding: 8px 0 8px 0 !important; }
  .u-py-xs {
    padding: 12px 0 12px 0 !important; }
  .u-py-s {
    padding: 16px 0 16px 0 !important; }
  .u-py-m {
    padding: 20px 0 20px 0 !important; }
  .u-py-l {
    padding: 24px 0 24px 0 !important; }
  .u-py-xl {
    padding: 32px 0 32px 0 !important; }
  .u-py-xxl {
    padding: 48px 0 48px 0 !important; }
  .u-py-xxxl {
    padding: 64px 0 64px 0 !important; }
  .u-py-xxxxl {
    padding: 96px 0 96px 0 !important; } }

.u-pr-xxxxs-desktop {
  padding-right: 0 !important; }

.u-pr-xxxs-desktop {
  padding-right: 0 !important; }

.u-pr-xxs-desktop {
  padding-right: 0 !important; }

.u-pr-xs-desktop {
  padding-right: 0 !important; }

.u-pr-s-desktop {
  padding-right: 0 !important; }

.u-pr-m-desktop {
  padding-right: 0 !important; }

.u-pr-l-desktop {
  padding-right: 0 !important; }

.u-pr-xl-desktop {
  padding-right: 0 !important; }

.u-pr-xxl-desktop {
  padding-right: 0 !important; }

.u-pr-xxxl-desktop {
  padding-right: 0 !important; }

.u-pr-xxxxl-desktop {
  padding-right: 0 !important; }

@media (min-width: 992px) {
  .u-pr-xxxxs-desktop {
    padding-right: 2px !important; }
  .u-pr-xxxs-desktop {
    padding-right: 4px !important; }
  .u-pr-xxs-desktop {
    padding-right: 8px !important; }
  .u-pr-xs-desktop {
    padding-right: 12px !important; }
  .u-pr-s-desktop {
    padding-right: 16px !important; }
  .u-pr-m-desktop {
    padding-right: 20px !important; }
  .u-pr-l-desktop {
    padding-right: 24px !important; }
  .u-pr-xl-desktop {
    padding-right: 32px !important; }
  .u-pr-xxl-desktop {
    padding-right: 48px !important; }
  .u-pr-xxxl-desktop {
    padding-right: 64px !important; }
  .u-pr-xxxxl-desktop {
    padding-right: 96px !important; } }

.u-pl-xxxxs-desktop {
  padding-left: 0 !important; }

.u-pl-xxxs-desktop {
  padding-left: 0 !important; }

.u-pl-xxs-desktop {
  padding-left: 0 !important; }

.u-pl-xs-desktop {
  padding-left: 0 !important; }

.u-pl-s-desktop {
  padding-left: 0 !important; }

.u-pl-m-desktop {
  padding-left: 0 !important; }

.u-pl-l-desktop {
  padding-left: 0 !important; }

.u-pl-xl-desktop {
  padding-left: 0 !important; }

.u-pl-xxl-desktop {
  padding-left: 0 !important; }

.u-pl-xxxl-desktop {
  padding-left: 0 !important; }

.u-pl-xxxxl-desktop {
  padding-left: 0 !important; }

@media (min-width: 992px) {
  .u-pl-xxxxs-desktop {
    padding-left: 2px !important; }
  .u-pl-xxxs-desktop {
    padding-left: 4px !important; }
  .u-pl-xxs-desktop {
    padding-left: 8px !important; }
  .u-pl-xs-desktop {
    padding-left: 12px !important; }
  .u-pl-s-desktop {
    padding-left: 16px !important; }
  .u-pl-m-desktop {
    padding-left: 20px !important; }
  .u-pl-l-desktop {
    padding-left: 24px !important; }
  .u-pl-xl-desktop {
    padding-left: 32px !important; }
  .u-pl-xxl-desktop {
    padding-left: 48px !important; }
  .u-pl-xxxl-desktop {
    padding-left: 64px !important; }
  .u-pl-xxxxl-desktop {
    padding-left: 96px !important; } }

.u-pt-xxxxs-desktop {
  padding-top: 0 !important; }

.u-pt-xxxs-desktop {
  padding-top: 0 !important; }

.u-pt-xxs-desktop {
  padding-top: 0 !important; }

.u-pt-xs-desktop {
  padding-top: 0 !important; }

.u-pt-s-desktop {
  padding-top: 0 !important; }

.u-pt-m-desktop {
  padding-top: 0 !important; }

.u-pt-l-desktop {
  padding-top: 0 !important; }

.u-pt-xl-desktop {
  padding-top: 0 !important; }

.u-pt-xxl-desktop {
  padding-top: 0 !important; }

.u-pt-xxxl-desktop {
  padding-top: 0 !important; }

.u-pt-xxxxl-desktop {
  padding-top: 0 !important; }

@media (min-width: 992px) {
  .u-pt-xxxxs-desktop {
    padding-top: 2px !important; }
  .u-pt-xxxs-desktop {
    padding-top: 4px !important; }
  .u-pt-xxs-desktop {
    padding-top: 8px !important; }
  .u-pt-xs-desktop {
    padding-top: 12px !important; }
  .u-pt-s-desktop {
    padding-top: 16px !important; }
  .u-pt-m-desktop {
    padding-top: 20px !important; }
  .u-pt-l-desktop {
    padding-top: 24px !important; }
  .u-pt-xl-desktop {
    padding-top: 32px !important; }
  .u-pt-xxl-desktop {
    padding-top: 48px !important; }
  .u-pt-xxxl-desktop {
    padding-top: 64px !important; }
  .u-pt-xxxxl-desktop {
    padding-top: 96px !important; } }

.u-pr-xxxxs-responsive {
  padding-right: 2px !important; }

.u-pr-xxxs-responsive {
  padding-right: 4px !important; }

.u-pr-xxs-responsive {
  padding-right: 8px !important; }

.u-pr-xs-responsive {
  padding-right: 12px !important; }

.u-pr-s-responsive {
  padding-right: 16px !important; }

.u-pr-m-responsive {
  padding-right: 20px !important; }

.u-pr-l-responsive {
  padding-right: 24px !important; }

.u-pr-xl-responsive {
  padding-right: 32px !important; }

.u-pr-xxl-responsive {
  padding-right: 48px !important; }

.u-pr-xxxl-responsive {
  padding-right: 64px !important; }

.u-pr-xxxxl-responsive {
  padding-right: 96px !important; }

@media (min-width: 992px) {
  .u-pr-xxxxs-responsive {
    padding-right: 0 !important; }
  .u-pr-xxxs-responsive {
    padding-right: 0 !important; }
  .u-pr-xxs-responsive {
    padding-right: 0 !important; }
  .u-pr-xs-responsive {
    padding-right: 0 !important; }
  .u-pr-s-responsive {
    padding-right: 0 !important; }
  .u-pr-m-responsive {
    padding-right: 0 !important; }
  .u-pr-l-responsive {
    padding-right: 0 !important; }
  .u-pr-xl-responsive {
    padding-right: 0 !important; }
  .u-pr-xxl-responsive {
    padding-right: 0 !important; }
  .u-pr-xxxl-responsive {
    padding-right: 0 !important; }
  .u-pr-xxxxl-responsive {
    padding-right: 0 !important; } }

.u-pl-xxxxs-responsive {
  padding-left: 2px !important; }

.u-pl-xxxs-responsive {
  padding-left: 4px !important; }

.u-pl-xxs-responsive {
  padding-left: 8px !important; }

.u-pl-xs-responsive {
  padding-left: 12px !important; }

.u-pl-s-responsive {
  padding-left: 16px !important; }

.u-pl-m-responsive {
  padding-left: 20px !important; }

.u-pl-l-responsive {
  padding-left: 24px !important; }

.u-pl-xl-responsive {
  padding-left: 32px !important; }

.u-pl-xxl-responsive {
  padding-left: 48px !important; }

.u-pl-xxxl-responsive {
  padding-left: 64px !important; }

.u-pl-xxxxl-responsive {
  padding-left: 96px !important; }

@media (min-width: 992px) {
  .u-pl-xxxxs-responsive {
    padding-left: 0 !important; }
  .u-pl-xxxs-responsive {
    padding-left: 0 !important; }
  .u-pl-xxs-responsive {
    padding-left: 0 !important; }
  .u-pl-xs-responsive {
    padding-left: 0 !important; }
  .u-pl-s-responsive {
    padding-left: 0 !important; }
  .u-pl-m-responsive {
    padding-left: 0 !important; }
  .u-pl-l-responsive {
    padding-left: 0 !important; }
  .u-pl-xl-responsive {
    padding-left: 0 !important; }
  .u-pl-xxl-responsive {
    padding-left: 0 !important; }
  .u-pl-xxxl-responsive {
    padding-left: 0 !important; }
  .u-pl-xxxxl-responsive {
    padding-left: 0 !important; } }

.u-pb-xxxxl .detail.detail-green--8 img {
  bottom: -85px; }

.text-legal {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 150px;
  font-size: 16px;
  word-wrap: break-word; }
  .text-legal h1, .text-legal h2, .text-legal h3, .text-legal h4 {
    line-height: 30px; }

.alert.form__message {
  text-align: center;
  font-size: 14px; }
  .alert.form__message--info {
    text-align: left;
    font-size: 14px; }
  .alert.form__message--error {
    color: get-color(gunsmoke, 230);
    background-color: #fde6df;
    padding: 13px 25px;
    border-radius: 5px; }
    .alert.form__message--error svg {
      color: #F63; }
    .alert.form__message--error .iconok {
      display: none; }
    .alert.form__message--error .iconerror {
      display: inline; }
  .alert.form__message--error-dark-bg {
    color: #3b3b3b; }
  .alert.form__message--success {
    color: get-color(gunsmoke, 230);
    background-color: #f2f7e4;
    padding: 13px 25px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .alert.form__message--success svg {
      color: #a2d21b;
      margin-right: 10px !important; }
    .alert.form__message--success .iconok {
      display: inline; }
    .alert.form__message--success .iconerror {
      display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*
@charset 'UTF-8';

/// ⚠️ This block requires this specific ORDER, not change the order!
@import
    'abstracts/specs',
    'abstracts/functions',
    'abstracts/generators',
    'abstracts/variables',
    'abstracts/mixins';


@import
    'vendor/normalize',
    './node_modules/swiper/swiper',
    './node_modules/swiper/components/a11y/a11y',
    // './node_modules/swiper/components/controller/controller',
    // './node_modules/swiper/components/effect-coverflow/effect-coverflow',
    // './node_modules/swiper/components/effect-cube/effect-cube',
    './node_modules/swiper/components/effect-fade/effect-fade',
    // './node_modules/swiper/components/effect-flip/effect-flip',
    // './node_modules/swiper/components/lazy/lazy',
    './node_modules/swiper/components/navigation/navigation',
    './node_modules/swiper/components/pagination/pagination',
    './node_modules/select2/src/scss/core',
    //'./node_modules/dropzone/src/dropzone',
    './node_modules/photoswipe/src/css/main',
    './vendor/jquery-ui-sortable/jquery-ui',
    './vendor/modulobox/css/modulobox';
    // './node_modules/swiper/components/scrollbar/scrollbar',
    // './node_modules/swiper/components/thumbs/thumbs',
    // './node_modules/swiper/components/zoom/zoom';


// Bootstrap 4 Grid
// This file include itself all the required stuff to
// get fully BS4 grid features
@import
    './node_modules/bootstrap/scss/bootstrap-grid',
    './node_modules/bootstrap/scss/mixins/hover',
    './node_modules/bootstrap/scss/mixins/table-row',
    './node_modules/bootstrap/scss/tables',
    './node_modules/bootstrap/scss/utilities/sizing';


@import
    'vendor/customization/bootstrap-custom',
    'vendor/customization/swiper-custom',
    'vendor/customization/photoswipe-custom',
    'vendor/customization/dropzone';


@import
    'base/base',
    'base/color',
    'base/fonts',
    'base/form-normalize',
    'base/misc',
    'base/space',
    'base/typography';


/// Layout components
@import
    'layout/animations',
    // 'layout/aligned-box',
    // 'layout/aside-fullwidth-layout',
    // 'layout/aside-cta-layout',
    // 'layout/aside-layout',
    'layout/backdrop',
    'layout/bar',
    'layout/bar-new',
    // 'layout/bulletin-card-grid',
    // 'layout/column-layout',
    // 'layout/edge-section',
    // 'layout/float-box-container',
    'layout/footer',
    'layout/footer-mobile',
    'layout/fullwidth-breaker',
    // 'layout/grid',
    // 'layout/halfs-container',
    // 'layout/halfs',
    'layout/header',
    'layout/header-mobile',
    'layout/header-new',
    // 'layout/modal',
    // 'layout/nts-grid',
    // 'layout/page-wrapper',
    // 'layout/promo',
    'layout/popup',
    'layout/pusher';
    // 'layout/scroll-area',
    // 'layout/services-card-grid',
    // 'layout/sloped';


/// Components → Atoms
@import
    // 'components/atoms/alert',
    // 'components/atoms/avatar',
    // 'components/atoms/back-to',
    // 'components/atoms/badge',
    // 'components/atoms/btn-ghost',
    // 'components/atoms/btn-link',
    // 'components/atoms/btn-op',
     'components/atoms/btn',
     'components/atoms/checkbox',
     'components/atoms/custom-scrollbar',
    // 'components/atoms/chip',
    'components/atoms/datepicker',
    // 'components/atoms/close-btn',
    // 'components/atoms/form-control',
    // 'components/atoms/form-upload',
    'components/atoms/hamburger',
    'components/atoms/icon',
    'components/atoms/input-number',
    // 'components/atoms/img-rotator',
    'components/atoms/image',
    'components/atoms/link', 
    'components/atoms/misc', 
    // 'components/atoms/map',
    // 'components/atoms/post-category',
    // 'components/atoms/post-date',
    // 'components/atoms/preloader',
    // 'components/atoms/progress',
    // 'components/atoms/radio',
    // 'components/atoms/rating',
    // 'components/atoms/search-results',
    // 'components/atoms/social-icon-list',
    'components/atoms/switch',
    // 'components/atoms/tabs',
    // 'components/atoms/tag', 
    'components/atoms/text',
    // 'components/atoms/comments';
    // 'components/atoms/to-top',
    // 'components/atoms/tooltip',
    // 'components/atoms/video-bg',
    'components/atoms/radiobutton',
    'components/atoms/timepicker',
    'components/atoms/video',
    'components/atoms/multifile';


/// Components → Molecules
@import
    'components/molecules/account',
    'components/molecules/ad',
    // 'components/molecules/accordion',
    // 'components/molecules/advert-form',
    // 'components/molecules/banner',
    'components/molecules/breadcrumb',
    'components/molecules/blog',
    'components/molecules/cms-custom',
    // 'components/molecules/bulletin-card',
    // 'components/molecules/bulletin-cta',
    // 'components/molecules/bulletin-info',
    // 'components/molecules/bulletin-list',
    // 'components/molecules/business-highlights',
    // 'components/molecules/business-list',
    // 'components/molecules/call-us',
    // 'components/molecules/card-carousel',
    // 'components/molecules/card-infos',
    // 'components/molecules/card',
    'components/molecules/card',
    // 'components/molecules/carousel',
    // 'components/molecules/chart-cta',
    'components/molecules/cookies',
    // 'components/molecules/download-attached',
    // 'components/molecules/chip-list',
    // 'components/molecules/dropdown',
    // 'components/molecules/equipment-carousel',
    // 'components/molecules/equipment-logo-carousel',
    // 'components/molecules/faqs',
    // 'components/molecules/faqs-list',
    // 'components/molecules/features-list',
    'components/molecules/features',
    'components/molecules/filter',
    // 'components/molecules/float-box',
    'components/molecules/form',
    'components/molecules/gallery',
    'components/molecules/gallery-mobile',
    'components/molecules/histogram',
    // 'components/molecules/hero-slide',
    // 'components/molecules/highlighted-cta',
    // 'components/molecules/html-content',
    // 'components/molecules/html-content-modal',
    // 'components/molecules/installation-list',
    // 'components/molecules/interest-list',
    // 'components/molecules/intro',
    'components/molecules/icon-text',
    'components/molecules/image-info',
    'components/molecules/info',
    'components/molecules/label',
    // 'components/molecules/link-list',
    'components/molecules/list',
    'components/molecules/more-info',
    'components/molecules/nav-click',
    'components/molecules/nav-main',
    'components/molecules/nav-main-v2',
    'components/molecules/nav-simple',
    'components/molecules/nav-vertical',
    'components/molecules/nav-responsive',
    'components/molecules/menu',
    'components/molecules/menu-drop',
    // 'components/molecules/main-new',
    // 'components/molecules/main-news',
    'components/molecules/map',
    // 'components/molecules/map-info-window',
    // 'components/molecules/mini-gallery',
    'components/molecules/mobile-menu',
    'components/molecules/nav-mobile',
    // 'components/molecules/more-services',
    // 'components/molecules/newsletter',
    // 'components/molecules/newsletter-promo',
    'components/molecules/user',
    // 'components/molecules/packs-list',
    // 'components/molecules/paginator',
    'components/molecules/pagination',
    // 'components/molecules/post-detail',
    // 'components/molecules/post-mini',
    'components/molecules/price',
    // 'components/molecules/related-list',
    // 'components/molecules/search-box',
    'components/molecules/search',
    'components/molecules/select2',
    // 'components/molecules/secondary-nav',
    // 'components/molecules/section-lists',
    // 'components/molecules/service',
    // 'components/molecules/service-carousel',
    // 'components/molecules/service-contact', 
    // 'components/molecules/service-content',
    // 'components/molecules/service-feature',
    // 'components/molecules/service-features',
    // 'components/molecules/service-list',
    // 'components/molecules/services-card',
    // 'components/molecules/services-cta',
    // 'components/molecules/services-premium', 
    // 'components/molecules/services-goto',
    // 'components/molecules/services-hero',
    'components/molecules/swiper',
    'components/molecules/show-more',
    'components/molecules/state', 
    'components/molecules/steps',
    'components/molecules/sidebar',
    'components/molecules/survey',
    // 'components/molecules/table-card',
    // 'components/molecules/table',
    // 'components/molecules/tabs-slider',
    // 'components/molecules/tag-ranking',
    'components/molecules/tabs',
    'components/molecules/text-image',
    'components/molecules/text-module',
    'components/molecules/title',
    'components/molecules/tooltip',
    'components/molecules/tracing',
    'components/molecules/ad',
    // 'components/molecules/toast';
    'components/molecules/video';



/// Pages
// @import
    // 'pages/demo-components',
    // 'pages/color-system',
    // 'pages/space-system',
    // 'pages/type-system',
    // 'pages/tests';


/// Legacy
// @import
//     'legacy/sample';


// Global modifiers
@import
    'modifiers/is-ios',
    'modifiers/navigation';


@import
    'utilities/absolut-positioning',
    'utilities/align',
    'utilities/border',
    'utilities/float',
    'utilities/height',
    'utilities/lazyload',
    'utilities/misc',
    'utilities/ratio-box',
    'utilities/responsive',
    'utilities/text-manage',
    'utilities/width';


    */
