
.alert{

    &.form__message{

        text-align:center;
        font-size:14px;

        &--info{
            text-align:left;
            font-size:14px;
        }

        &--error{
            color:get-color(gunsmoke, 230);
            background-color: #fde6df;
            padding: 13px 25px;
            border-radius: 5px;

            & svg{
                color: #F63;
            }

            .iconok {
                display: none;
            }

            .iconerror {
                display: inline;
            }
        }

        &--error-dark-bg{
            color:#3b3b3b;

            
        }

        &--success{
            color:get-color(gunsmoke, 230);
            background-color: #f2f7e4;
            padding: 13px 25px;
            border-radius: 5px;
            display:flex;
            align-items:center;
            justify-content:center;

            & svg{
                color: #a2d21b;
                margin-right:10px !important;
            }

            .iconok {
                display: inline;
            }

            .iconerror {
                display: none;
            }
        }
    }

}